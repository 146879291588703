import { Modal } from "antd";
import { RowSelectionType } from "antd/lib/table/interface";
import { AxiosError } from "axios";
import { TFunction } from "i18next";
import { AppUser, AppUserFilter } from "models/AppUser";
import { AppUserGrouping, AppUserGroupingFilter } from "models/AppUserGrouping";
import { Organization } from "models/Organization";
import { Status } from "models/Status";
import React, {
  ChangeEvent,
  Key,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IdFilter } from "react3l-advanced-filters";
import { Model, ModelFilter } from "react3l-common";
import { Reducer } from "redux";
import { appUserGroupingRepository } from "repositories/app-user-grouping-repository";
import { finalize, forkJoin, Observable } from "rxjs";
import appMessageService from "services/common-services/app-message-service";
import { webService } from "services/common-services/web-service";
import { detailService } from "services/page-services/detail-service";
import {
  FilterAction,
  filterReducer,
} from "services/page-services/filter-service";
export function useAppUserGroupingTreeMasterHook(
  translate: TFunction,
  setListTree: (value: React.SetStateAction<any[]>) => void,
  loadingTable?: boolean,
  loadingTree?: boolean,
  setLoadingTable?: (value: React.SetStateAction<boolean>) => void,
  setLoadingTree?: (value: React.SetStateAction<boolean>) => void,
  listTree?: AppUserGrouping[],
  internalExpandedKeys?: number[],
  setInternalExpandedKeys?: React.Dispatch<React.SetStateAction<number[]>>,
  filter?: AppUserGroupingFilter,
  handleChangeAllFilter?: any,
  setIsCallListStart?: (data: boolean) => void
) {
  const [currentNode, setCurrentNode] = useState<AppUserGrouping>(
    new AppUserGrouping()
  );
  const [isActive, setActive] = useState<boolean>(false);
  const {
    notifyUpdateItemSuccess,
    notifyUpdateItemError,
    // notifyDeleteItemSuccess,
  } = appMessageService.useCRUDMessage();
  const [isOpenDetailModal, setIsOpenDetailModal] = useState<boolean>(false);
  const [currentOrganization, setCurrentOrganization] = useState<Organization>(
    new Organization()
  );
  const [loadingModel, setLoadingModel] = useState<boolean>(false);
  // const [oldeNodeId, setOldNodeId] = useState<number>(undefined);
  const [subscription] = webService.useSubscription();
  const [list, setList] = useState<AppUser[]>([]);
  const [count, setCount] = useState<number>(null);

  const [filterAppUser, setFilterAppUser] = React.useReducer<
    Reducer<ModelFilter, FilterAction<ModelFilter>>
  >(filterReducer, new AppUserFilter());

  const [isCreateChild, setIsCreateChild] = React.useState<boolean>(false);

  const {
    model,
    handleChangeAllField,
    handleChangeSingleField,
    handleChangeTreeField,
    handleChangeSelectField,
  } = detailService.useDetailModal(
    AppUserGrouping,
    appUserGroupingRepository.get,
    appUserGroupingRepository.save
  );

  // Open drawer to edit org info
  const handleOpenDetailModal = useCallback(
    (id: number) => {
      if (!loadingTable && !loadingTree) {
        setIsOpenDetailModal(true);
        if (id) {
          setLoadingModel(true);
          subscription.add(
            appUserGroupingRepository
              .get(id)
              .pipe(finalize(() => setLoadingModel(false)))
              .subscribe((item: AppUserGrouping) => {
                handleChangeAllField(item);
              })
          );
        }
      }
    },
    [handleChangeAllField, loadingTable, loadingTree, subscription]
  );

  const handleGoCreate = React.useCallback(
    (node: AppUserGrouping) => () => {
      if (!loadingTable && !loadingTree) {
        const newModel = new AppUserGrouping();
        if (node?.id) {
          setIsCreateChild(true);
          newModel.parent = node;
          newModel.parentId = node?.id;
          newModel.organization = node.organization;
          newModel.organizationId = node.organizationId;
        } else {
          setIsCreateChild(false);
        }
        handleChangeAllField(newModel);
        handleOpenDetailModal(null);
      }
    },
    [handleChangeAllField, handleOpenDetailModal, loadingTable, loadingTree]
  );

  // Kiểm tra 1 node có xuất hiện nút arrow ở trước hay không
  const handleCheckDisplayOfArrowButton = React.useCallback(
    (nodeId: number, listTree: AppUserGrouping[]) => {
      let tempListTree = [];
      for (var i = 0; i < listTree.length; i++) {
        let ids = tempListTree.length > 0 ? tempListTree.map((o) => o?.id) : [];
        if (!ids.includes(listTree[i]?.id)) {
          tempListTree.push(listTree[i]);
        }
      }
      // Kiểm tra xem trong listTree hiện tại, node đang xét có children hay không
      let countChildOfNode = 0;
      tempListTree &&
        tempListTree?.length > 0 &&
        tempListTree.map((o) => {
          if (o?.parentId === nodeId) countChildOfNode++;
          return countChildOfNode;
        });
      // Tìm kiếm node đang xét trong listTree và index của nó để chuẩn bị modify
      let nodeFinded = tempListTree.find((node) => node?.id === nodeId);
      let indexOfNodeFinded = tempListTree.indexOf(nodeFinded);
      tempListTree.splice(indexOfNodeFinded, 1);
      if (nodeFinded) {
        if (countChildOfNode > 0) {
          nodeFinded.hasChildren = true;
        } else {
          nodeFinded.hasChildren = false;
        }
        // Thêm node đã chỉnh sửa vào listTree
        tempListTree.splice(indexOfNodeFinded, 0, nodeFinded);
      }
      return tempListTree;
    },
    []
  );
  // Delete org on tree
  const handleDelete = useCallback(
    (item: AppUserGrouping) => {
      if (!loadingTable && !loadingTree) {
        setLoadingTree(true);
        subscription.add(
          appUserGroupingRepository
            .delete(item)
            .pipe(
              finalize(() => {
                setLoadingTree(false);
              })
            )
            .subscribe(
              (item: AppUserGrouping) => {
                let listTmp = listTree.filter((i) => i.id !== item.id);
                if (item?.parentId) {
                  listTmp = handleCheckDisplayOfArrowButton(
                    item?.parentId,
                    listTmp
                  );
                }

                setListTree(listTmp);
                let tempListKey =
                  internalExpandedKeys.length > 0
                    ? [...internalExpandedKeys]
                    : [];
                tempListKey = tempListKey.filter((o) => o !== item?.id);
                setInternalExpandedKeys(tempListKey);
                if (item?.id === currentNode?.id) setCurrentNode(listTmp[0]);
                notifyUpdateItemSuccess({
                  message: translate("general.message.deleteSuccess"),
                });
              },
              (err) => {
                notifyUpdateItemError({
                  message: translate("general.message.deleteError"),
                  description: err.response.data.errors.id,
                });
              }
            )
        );
      }
    },
    [
      currentNode?.id,
      handleCheckDisplayOfArrowButton,
      internalExpandedKeys,
      listTree,
      loadingTable,
      loadingTree,
      notifyUpdateItemError,
      notifyUpdateItemSuccess,
      setInternalExpandedKeys,
      setListTree,
      setLoadingTree,
      subscription,
      translate,
    ]
  );

  const onClickDeleteIcon = useCallback(
    (item: AppUserGrouping) => {
      Modal.confirm({
        title: translate("general.delete.content"),

        okType: "danger",
        onOk() {
          handleDelete(item);
        },
      });
    },
    [handleDelete, translate]
  );

  // Xóa những node trùng trên tree sau các thao tác
  const handleRemoveDuplicateNode = React.useCallback(
    (concatListTree: AppUserGrouping[]) => {
      var newListTree: AppUserGrouping[] = [];
      for (var i = 0; i < concatListTree.length; i++) {
        let ids = newListTree.length > 0 ? newListTree.map((o) => o?.id) : [];
        if (!ids.includes(concatListTree[i]?.id)) {
          newListTree.push(concatListTree[i]);
        }
      }
      return newListTree;
    },
    []
  );

  const handleFindChildrenOfNode = React.useCallback(
    (id: number, level: number, item?: AppUserGrouping) => {
      setLoadingTree(true);
      const tempFilter = { ...filter };
      tempFilter["level"]["equal"] = level;

      if (!tempFilter["IsFilterTree"]) {
        tempFilter["parentId"]["equal"] = id;
        tempFilter["activeNodeId"] = new IdFilter();
      } else {
        tempFilter["parentId"] = new IdFilter();
      }

      appUserGroupingRepository
        .list(tempFilter)
        .pipe(finalize(() => setLoadingTree(false)))
        .subscribe((res) => {
          // Thêm id node cha hiện tại vào expandedKey của tree
          let tempListKey =
            internalExpandedKeys.length > 0 ? [...internalExpandedKeys] : [];
          tempListKey.push(id);
          tempListKey = Array.from(new Set(tempListKey));
          tempListKey = tempListKey.filter(
            (o) => o !== null && o !== undefined
          );

          setInternalExpandedKeys(tempListKey);
          // Thêm các node con tìm được vào list để hiển thị trên tree
          let tempListTree = [...listTree];

          if (item) {
            let nodeFinded = listTree.find((node) => node?.id === item?.id);
            if (nodeFinded) {
              tempListTree.splice(tempListTree.indexOf(nodeFinded), 1);
            }
          }
          let concatListTree = [...tempListTree, ...res];
          concatListTree = handleRemoveDuplicateNode(concatListTree);
          if (id) {
            concatListTree = handleCheckDisplayOfArrowButton(
              id,
              concatListTree
            );
          }

          setListTree(concatListTree);
        });
    },
    [
      filter,
      handleCheckDisplayOfArrowButton,
      handleRemoveDuplicateNode,
      internalExpandedKeys,
      listTree,
      setInternalExpandedKeys,
      setListTree,
      setLoadingTree,
    ]
  );
  const handleGetDetail = React.useCallback(
    (filter: AppUserFilter) => {
      appUserGroupingRepository
        .get(filter["id"]["equal"])
        .pipe(
          finalize(() => {
            setLoadingTable(false);
          })
        )
        .subscribe((res) => {
          if (res) {
            setCurrentNode(res);
          }
        });
    },
    [setLoadingTable]
  );

  const handleLoadList = useCallback(
    (filterData?: AppUserFilter) => {
      const newFilter = filterData ? filterData : filterAppUser;
      setLoadingTable(true);
      subscription.add(
        forkJoin([
          appUserGroupingRepository.listAppUser(newFilter),
          appUserGroupingRepository.countAppUser(newFilter),
        ])
          .pipe(finalize(() => setLoadingTable(false)))
          .subscribe({
            next: (results: [AppUser[], number]) => {
              setList(results[0]);
              setCount(results[1]);
            },
            error: (err: any) => {
              setList(null);
              setCount(null);
            },
          })
      );
    },
    [filterAppUser, setLoadingTable, subscription]
  );

  // Save in drawer
  const handleSaveModel = useCallback(() => {
    setLoadingModel(true);
    subscription.add(
      appUserGroupingRepository
        .save(model)
        .pipe(finalize(() => setLoadingModel(false)))
        .subscribe(
          (item: AppUserGrouping) => {
            if (filterAppUser["id"]["equal"]) {
              handleGetDetail(filterAppUser);
            }
            if (isCreateChild) {
              handleFindChildrenOfNode(item?.parentId, item?.level, item);
            } else {
              setIsCallListStart(true);
            }

            setCurrentNode(item);
            handleChangeAllField(item); // setModel
            setIsOpenDetailModal(false); // close Modal
            notifyUpdateItemSuccess(); // global message service go here
          },
          (error: AxiosError<AppUserGrouping>) => {
            if (error.response && error.response.status === 400)
              handleChangeAllField(error.response?.data);
            notifyUpdateItemError();
          }
        )
    );
  }, [
    subscription,
    model,
    filterAppUser,
    isCreateChild,
    handleChangeAllField,
    notifyUpdateItemSuccess,
    handleGetDetail,
    handleFindChildrenOfNode,
    setIsCallListStart,
    notifyUpdateItemError,
  ]);

  // Hàm lấy ra các kết quả tìm kiếm
  const handleFilterTree = React.useCallback(
    (filter?: AppUserGroupingFilter, resetFilter?: boolean) => {
      setLoadingTree(true);
      appUserGroupingRepository
        .list(filter)
        .pipe(finalize(() => setLoadingTree(false)))
        .subscribe((res: any) => {
          if (currentNode?.id) {
            if (filter["IsFilterTree"] && !resetFilter) {
              let tempListTree: AppUserGrouping[] = [];
              let tempInternalExpandedKeys: number[] = [];
              let listIdParentOfRes = currentNode?.path.split(".");
              listIdParentOfRes.pop();
              listIdParentOfRes &&
                listIdParentOfRes?.length > 0 &&
                // eslint-disable-next-line array-callback-return
                listIdParentOfRes.map((o) => {
                  let nodeFinded = listTree.find(
                    (node) => String(node?.id) === o
                  );
                  tempListTree.push(nodeFinded);
                  tempInternalExpandedKeys.push(Number(o));
                });
              setInternalExpandedKeys(tempInternalExpandedKeys);
              tempListTree = [...tempListTree, ...res];
              tempListTree = handleCheckDisplayOfArrowButton(
                currentNode?.id,
                tempListTree
              );
              setListTree(tempListTree);
            } else {
              setListTree(res);
            }
          } else {
            setListTree(res);
          }
        });
    },
    [
      currentNode,
      handleCheckDisplayOfArrowButton,
      listTree,
      setInternalExpandedKeys,
      setListTree,
      setLoadingTree,
    ]
  );

  React.useEffect(() => {
    if (filter?.organizationId?.equal) {
      const organization = filter.organizationValue;
      setCurrentOrganization(organization);
    }
  }, [filter?.organizationId?.equal, filter.organizationValue]);

  const handleChangeTreeAppUserGroupingFilter = React.useCallback(
    (values: Organization[]) => {
      const value = values[0];
      let tempFilter = { ...filter };
      tempFilter["level"]["equal"] = undefined;
      let resetFilter = false; // flag đánh dấu khi nào tất cả bộ lọc bị xóa hết
      if (value) {
        setCurrentOrganization(value);
        tempFilter["organizationId"]["equal"] = value.id;
        tempFilter["organizationValue"] = value;
        tempFilter["organization"] = value;
        tempFilter["IsFilterTree"] = true;
      } else {
        setCurrentOrganization(undefined);
        tempFilter["organizationId"]["equal"] = undefined;
        tempFilter["organizationValue"] = undefined;
        tempFilter["organization"] = undefined;
      }
      // Nếu không tìm kiếm gì thì reset lại cây
      if (!tempFilter["search"] && !tempFilter["organizationId"]["equal"]) {
        setInternalExpandedKeys([]);
        setCurrentNode(undefined);
        resetFilter = true;
        tempFilter = {
          ...new AppUserGroupingFilter(),
          parentId: { equal: undefined },
          level: {
            equal: 1,
          },
          skip: 0,
          take: 100000,
        };
      }
      handleChangeAllFilter(tempFilter);
      handleFilterTree(tempFilter, resetFilter);
    },
    [filter, handleChangeAllFilter, handleFilterTree, setInternalExpandedKeys]
  );

  // Filter tại ô tìm kiếm
  const handleChangeInputSearchAppUserGroupingFilter = React.useCallback(
    (value: any) => {
      let tempFilter = { ...filter };
      tempFilter["level"]["equal"] = undefined;
      let resetFilter = false; // flag đánh dấu khi nào tất cả bộ lọc bị xóa hết
      if (value) {
        tempFilter["search"] = value;
        tempFilter["IsFilterTree"] = true;
      } else {
        tempFilter["search"] = undefined;
        tempFilter["skip"] = 0;
        tempFilter["take"] = 100000;
      }
      // Nếu không tìm kiếm gì thì reset lại cây
      if (!tempFilter["search"] && tempFilter["organizationId"]["equal"]) {
        setInternalExpandedKeys([]);
        setCurrentNode(undefined);
        resetFilter = true;
        tempFilter = {
          ...new AppUserGroupingFilter(),
          parentId: { equal: undefined },
          level: {
            equal: 1,
          },
          skip: 0,
          take: 100000,
        };
      }
      handleChangeAllFilter(tempFilter);
      handleFilterTree(tempFilter, resetFilter);
    },
    [filter, handleChangeAllFilter, handleFilterTree, setInternalExpandedKeys]
  );

  // Hàm xử lý khi ấn vào mũi tên của 1 node
  const handleClickIconNode = useCallback(
    (node) => {
      setCurrentNode(node);
      if (!loadingTable && !loadingTree) {
        // display children of chosen node
        const newTreeFilter = {
          ...filter,
        };
        setLoadingTree(true);
        appUserGroupingRepository
          .list({
            ...new AppUserGroupingFilter(),
            parentId: { equal: node?.id },
            level: { equal: undefined },
          })
          .pipe(finalize(() => setLoadingTree(false)))
          .subscribe((res: AppUserGrouping[]) => {
            let tempListTree =
              listTree && listTree?.length > 0 ? [...listTree] : [];
            // Nếu đang filter, khi ấn vào 1 node, cần xóa hết con cũ của nó, thay node mới vào để đúng với bộ lọc hiện tại
            if (newTreeFilter["IsFilterTree"] && tempListTree.length > 0) {
              tempListTree = tempListTree.filter(
                (o) => o?.parentId !== node?.id
              );
            }

            let concatListTree = [...tempListTree, ...res];
            concatListTree = handleRemoveDuplicateNode(concatListTree);
            if (res?.length === 0) {
              concatListTree &&
                concatListTree?.length > 0 &&
                concatListTree.map((o) => {
                  if (o?.id === node?.id) {
                    o.hasChildren = false;
                  }
                  return o;
                });
            }
            setListTree(concatListTree);
          });
      }
    },
    [
      filter,
      handleRemoveDuplicateNode,
      listTree,
      loadingTable,
      loadingTree,
      setListTree,
      setLoadingTree,
    ]
  );

  useEffect(() => {
    if (filterAppUser && currentNode?.id) {
      setLoadingTable(true);
      // setOldNodeId(currentNode.id);
      setLoadingTable(true);
      subscription.add(
        forkJoin([
          appUserGroupingRepository.listAppUser(filterAppUser),
          appUserGroupingRepository.countAppUser(filterAppUser),
        ])
          .pipe(finalize(() => setLoadingTable(false)))
          .subscribe({
            next: (results: [AppUser[], number]) => {
              setList(results[0]);
              setCount(results[1]);
            },
            error: (err: any) => {
              setList(null);
              setCount(null);
            },
          })
      );
    }
  }, [currentNode, filterAppUser, setLoadingTable, subscription]);
  /* Get list appUser*/

  const handleLoadListAppUser = React.useCallback(
    (id) => {
      setFilterAppUser({
        type: 1,
        payload: {
          ...filterAppUser,
          appUserGroupingId: { equal: id },
          dependencyTypeId: Object.assign(new IdFilter(), {
            equal: 1,
          }),
          dependencyTypeValue: {
            id: 1,
            code: "ALL",
            name: "Tất cả",
          } as Status,
          skip: 0,
          take: 10,
        },
      });
    },
    [filterAppUser]
  );

  const handleEnumFilter = useCallback(
    (value) => {
      setFilterAppUser({
        type: 1,
        payload: {
          ...filterAppUser,
          skip: 0,
          take: 10,
          dependencyTypeId: { equal: value },
        },
      });
    },
    [filterAppUser]
  );

  const handleChangeAppUserFilter = useCallback(
    (value) => {
      setFilterAppUser({
        type: 1,
        payload: {
          ...filterAppUser,
          skip: 0,
          take: 10,
          search: value,
        },
      });
    },
    [filterAppUser]
  );

  const handleDeleteAppUser = React.useCallback(
    (appUser) => {
      const index = currentNode.appUsers
        .map((item: AppUser) => item.id)
        .indexOf(appUser.id);
      Modal.confirm({
        title: translate("general.delete.content"),
        okType: "danger",
        onOk() {
          currentNode.appUsers.splice(index);

          appUserGroupingRepository.removeAppUser(currentNode).subscribe(
            (res) => {
              notifyUpdateItemSuccess();
              setCurrentNode(res);
            },
            (err) => {
              notifyUpdateItemError();
            }
          );
        },
      });
    },
    [currentNode, notifyUpdateItemError, notifyUpdateItemSuccess, translate]
  );

  const handleCloseDetailModal = useCallback(() => {
    setIsOpenDetailModal(false);
    handleChangeAllField({ ...new AppUserGrouping() });
  }, [handleChangeAllField]);

  // Hàm xử lý khi ấn vào tiêu đề của 1 node
  const handleClickTitleNode = useCallback(
    (node) => {
      setCurrentNode(node);
      setFilterAppUser({
        type: 1,
        payload: { ...new AppUserFilter(), skip: 0, take: 10 },
      });
      if (!loadingTree) {
        setFilterAppUser({
          type: 1,
          payload: {
            ...new AppUserFilter(),
            appUserGroupingId: { equal: node?.id },
            dependencyTypeId: Object.assign(new IdFilter(), {
              equal: 1,
            }),
            dependencyTypeValue: {
              id: 1,
              code: "ALL",
              name: "Tất cả",
            } as Status,
            skip: 0,
            take: 10,
          },
        });
        handleLoadListAppUser(node?.id);
      }
      setActive(true);
    },
    [handleLoadListAppUser, loadingTree]
  );

  // handle Import Error
  const handleImportError = useCallback(
    (error: AxiosError<any>) => {
      Modal.error({
        title: translate("general.update.error"),
        content: error.response.data,
      });
    },
    [translate]
  );
  const handleImportList = React.useCallback(() => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files.length > 0) {
        const file: File = event.target.files[0];
        subscription.add(
          appUserGroupingRepository.import(file).subscribe({
            next: (res: any) => {
              let tempListTree =
                listTree && listTree?.length > 0 ? [...listTree] : [];

              let concatListTree = [...tempListTree, ...res];
              concatListTree = handleRemoveDuplicateNode(concatListTree);
              setListTree(concatListTree);
              Modal.success({
                content: translate("general.update.success"),
              });
            }, // onSuccess
            error: (err: any) => handleImportError(err),
          })
        );
      }
    };
  }, [
    subscription,
    listTree,
    handleRemoveDuplicateNode,
    setListTree,
    translate,
    handleImportError,
  ]);

  return {
    isActive,
    currentNode,
    setCurrentNode,
    handleClickIconNode,
    handleClickTitleNode,
    onClickDeleteIcon,
    filterAppUser,
    handleDeleteAppUser,
    handleEnumFilter,
    handleRemoveDuplicateNode,
    isOpenDetailModal,
    loadingModel,
    handleOpenDetailModal,
    handleGoCreate,
    handleSaveModel,
    handleCloseDetailModal,
    model,
    handleChangeSingleField,
    handleChangeTreeField,
    handleChangeAllField,
    handleChangeTreeAppUserGroupingFilter,
    handleChangeInputSearchAppUserGroupingFilter,
    handleImportList,
    handleChangeSelectField,
    setFilterAppUser,
    handleLoadList,
    handleChangeAppUserFilter,
    list,
    count,
    currentOrganization,
  };
}

export function useRowSelection<T extends Model>(
  action?: (t: T) => Observable<T>,
  bulkAction?: (ids?: any) => Observable<void>,
  selectionType: RowSelectionType = "checkbox",
  initialRowKeys?: Key[],
  onUpdateListSuccess?: (item?: T) => void,
  handleResetList?: () => void,
  checkUsed?: boolean,
  model?: T
) {
  const [subscription] = webService.useSubscription();

  const { notifyUpdateItemSuccess, notifyUpdateItemError } =
    appMessageService.useCRUDMessage();

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>(
    initialRowKeys ?? []
  );

  const canBulkAction = useMemo(
    () => selectedRowKeys.length > 0,
    [selectedRowKeys.length]
  );

  const rowSelection = useMemo(
    () => ({
      onChange(selectedRowKeys: Key[]) {
        setSelectedRowKeys(selectedRowKeys);
      },
      selectedRowKeys,
      type: selectionType,
      getCheckboxProps: (record: T) => ({
        disabled: record.isChild ? false : true, // Column configuration not to be checked
      }),
    }),
    [selectedRowKeys, selectionType]
  );

  const handleAction = useCallback(
    (item: T) => {
      const delItem = { ...item, appUserId: item?.appUser?.id };
      if (typeof action !== undefined) {
        subscription.add(
          action(delItem).subscribe({
            next: (_res) => {
              if (typeof onUpdateListSuccess === "function") {
                onUpdateListSuccess(item);
              }
              setSelectedRowKeys(
                (selectedRowKeys as number[]).filter((id) => id !== item.id)
              );
              notifyUpdateItemSuccess({
                message: "Xóa thành công",
              });
              handleResetList();
            },
            error: () => {
              notifyUpdateItemError({
                message: "Xóa thất bại",
              });
            },
          })
        );
      }
    },
    [
      action,
      subscription,
      onUpdateListSuccess,
      selectedRowKeys,
      notifyUpdateItemSuccess,
      handleResetList,
      notifyUpdateItemError,
    ]
  );

  const handleBulkAction = useCallback(
    (keys?: Key[]) => {
      if (typeof bulkAction !== undefined) {
        const object = keys.map((id) => {
          return { appUserGroupingId: model?.id, appUserId: id };
        });
        subscription.add(
          bulkAction(object).subscribe({
            next: (_res) => {
              if (typeof onUpdateListSuccess === "function") {
                onUpdateListSuccess();
              }
              setSelectedRowKeys([]);
              notifyUpdateItemSuccess({
                message: "Xóa thành công",
              });
              handleResetList();
            },
            error: () => {
              notifyUpdateItemError({
                message: "Xóa thất bại",
              });
            },
          })
        );
      }
    },
    [
      bulkAction,
      subscription,
      model,
      onUpdateListSuccess,
      notifyUpdateItemSuccess,
      handleResetList,
      notifyUpdateItemError,
    ]
  );

  React.useEffect(() => {
    if (model?.id) {
      setSelectedRowKeys([]);
    }
  }, [model]);

  return {
    handleAction,
    handleBulkAction,
    canBulkAction,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
  };
}
