import { DiagramEditor } from "components/Diagram/codebase/diagramWithEditor";
import { WorkflowDirection } from "models/WorkflowDirection";
import React from "react";
import {
  detailService,
  ModelAction,
  ModelActionEnum,
} from "services/page-services/detail-service";
import { v4 as uuidv4 } from "uuid";
// import { useTranslation } from "react-i18next";

export interface WorkflowDirectionDetailModalProps {
  visible: boolean;
  handleClose: () => void;
  handleSave: () => void;
  model: WorkflowDirection;
  dispatch: React.Dispatch<ModelAction<WorkflowDirection>>;
}

function useWorkflowDirectionDetailModal(editor: DiagramEditor) {
  const [visibleModal, setVisibleModal] = React.useState(false);
  const { model, dispatch } =
    detailService.useModel<WorkflowDirection>(WorkflowDirection);

  const openModal = React.useCallback(
    (workflowDirection?: any) => {
      if (workflowDirection) {
        dispatch({ type: ModelActionEnum.SET, payload: workflowDirection });
      }

      setVisibleModal(true);
    },
    [dispatch]
  );

  const closeModal = React.useCallback(() => {
    if (model.isNew) {
      editor?.diagram?.data?.remove(model.id);
    }
    setVisibleModal(false);
  }, [editor?.diagram?.data, model.id, model.isNew]);

  const saveModal = React.useCallback(() => {
    if (model.isNew) {
      model.isNew = !model.isNew;
      model.isOld = true;
    }
    editor?.diagram?.data?.update(model.id, model);
    const titleText = model.workflowDirectionConditions?.map((condition) => {
      if (!condition.id) {
        condition.rowId = uuidv4();
      }

      return {
        conditionId: condition.id ?? condition.rowId,
        text:
          condition.workflowParameter.name +
            " " +
            condition.workflowOperator.name +
            " " +
            condition?.masterData?.name ?? condition.value,
      };
    });
    model.title.text = titleText;
    editor?.parse(editor?.serialize());
    setVisibleModal(false);
  }, [editor, model]);

  return {
    model,
    dispatch,
    visibleModal,
    openModal,
    closeModal,
    saveModal,
  };
}

export default useWorkflowDirectionDetailModal;
