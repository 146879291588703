import { DiagramEditor } from "components/Diagram/codebase/diagramWithEditor";
import {
  convertModelDataToDiagramData,
  leftPanelConvertData,
  newStep,
} from "components/Diagram/helper/convert-data";
import { WorkflowDefinition } from "models/WorkflowDefinition";
import { WorkflowStep } from "models/WorkflowStep";
import React from "react";

function useWorkflowDefinitionDetail(
  workflowDefinition: WorkflowDefinition,
  editor?: DiagramEditor
) {
  const [inactiveSteps, setInactiveSteps] =
    React.useState<WorkflowStep[]>(null);

  React.useEffect(() => {
    if (
      workflowDefinition &&
      workflowDefinition.workflowSteps &&
      workflowDefinition.workflowSteps.length > 0
    ) {
      const inactiveStepList = workflowDefinition.workflowSteps.filter(
        (x) => x.statusId !== 1
      );
      setInactiveSteps([...inactiveStepList]);
    }
  }, [workflowDefinition]);

  const newStepLeftPanel = React.useMemo(() => {
    return newStep();
  }, []);

  const diagramData = React.useMemo(() => {
    return convertModelDataToDiagramData(workflowDefinition);
  }, [workflowDefinition]);

  const leftPanelData = React.useMemo(() => {
    return leftPanelConvertData(inactiveSteps);
  }, [inactiveSteps]);

  return {
    diagramData,
    leftPanelData,
    newStepLeftPanel,
    inactiveSteps,
    setInactiveSteps,
  };
}

export default useWorkflowDefinitionDetail;
