import { NumberFilter, StringFilter } from "react3l-advanced-filters";
import { ModelFilter } from "react3l-common";
import { ObjectField } from "react3l-decorators";

export class FileTemplateInputFilter extends ModelFilter {
  @ObjectField(NumberFilter)
  public id: NumberFilter = new NumberFilter();
  @ObjectField(StringFilter)
  public fieldName: StringFilter = new StringFilter();
  @ObjectField(StringFilter)
  public fieldValue: StringFilter = new StringFilter();
  @ObjectField(NumberFilter)
  public order: NumberFilter = new NumberFilter();
}
