import { ColumnProps } from "antd/lib/table";
import {
  LoginConfigurationContent,
  LoginConfigurationContentFilter,
} from "models/LoginConfigurationContent";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Model } from "react3l-common";
import {
  FormItem,
  InputText,
  // InputText,
  LayoutCell,
  LayoutHeader,
  OneLineText,
} from "react3l-ui-library";
// import { supplierRepository } from "repositories/supplier-repository";
import { utilService } from "services/common-services/util-service";
import { fieldService } from "services/page-services/field-service";
import {
  FilterAction,
  filterReducer,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { tableService } from "services/page-services/table-service";
import nameof from "ts-nameof.macro";

export function useLoginConfigurationContent(model?: Model, dispatch?: any) {
  const [translate] = useTranslation();

  const [filter, dispatchFilter] = React.useReducer<
    React.Reducer<
      LoginConfigurationContentFilter,
      FilterAction<LoginConfigurationContentFilter>
    >
  >(filterReducer, new LoginConfigurationContentFilter());

  const { handleChangeListField } = fieldService.useField(model, dispatch);

  const { list, count } = listService.useLocalList(
    model?.loginConfigurationContents,
    filter
  );

  const {
    handleResetTable,
    handlePagination,
    handleChangeCell,
    // handleChangeRow,
    // handleAddRow,
    // handleDeleteRow,
  } = tableService.useContentTable(
    LoginConfigurationContentFilter,
    LoginConfigurationContent,
    list,
    handleChangeListField({ fieldName: "loginConfigurationContents" }),
    dispatchFilter
  );

  const handleChangeField = React.useCallback(
    (params, fieldName) => (value?: string | null) => {
      handleChangeCell(params[1]?.key, fieldName, value);
    },
    [handleChangeCell]
  );

  const columns: ColumnProps<LoginConfigurationContent>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType={"left"}
            title={translate(
              "loginConfigurations.loginConfigurationContents.code"
            )}
          />
        ),

        key: nameof(list[0].code),
        dataIndex: nameof(list[0].code),
        ellipsis: true,
        render(...params: [string, LoginConfigurationContent, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: (
          <LayoutHeader
            orderType={"left"}
            title={translate(
              "loginConfigurations.loginConfigurationContents.name"
            )}
          />
        ),
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        ellipsis: true,
        render(...params: [string, LoginConfigurationContent, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType={"left"}
            title={translate(
              "loginConfigurations.loginConfigurationContents.value"
            )}
          />
        ),

        key: nameof(list[0].value),
        dataIndex: nameof(list[0].value),
        ellipsis: true,
        render(...params: [number, LoginConfigurationContent, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg" className="p-r--xxs">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].value)
                )}
                message={params[1].errors?.value}
              >
                <InputText
                  type={0}
                  isRequired
                  value={params[1]?.value}
                  placeHolder={translate(
                    "loginConfigurations.loginConfigurationContents.placeholder.value"
                  )}
                  className={"tio-account_square_outlined"}
                  onChange={handleChangeField(params, "value")}
                />
              </FormItem>
            </LayoutCell>
          );
        },
      },
    ],
    [translate, list, handleChangeField]
  );

  return {
    handleResetTable,
    handlePagination,
    columns,
    list,
    count,
    filter,
  };
}
