import { APP_USER_GROUPING_MASTER_ROUTE } from "config/route-consts";
import { Switch } from "react-router-dom";

import { ProtectedRoute } from "pages/Authentication/ProtectedRoute";
import AppUserGroupingMaster from "./AppUserGroupingMaster/AppUserGroupingMaster";
import { authorizationService } from "services/common-services/authorization-service";

function AppUserGroupingView() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { auth } = authorizationService.useAuthorizedRoute();

  return (
    <Switch>
      <ProtectedRoute
        path={APP_USER_GROUPING_MASTER_ROUTE}
        key={APP_USER_GROUPING_MASTER_ROUTE}
        component={AppUserGroupingMaster}
        auth={true}
      />
    </Switch>
  );
}

export { AppUserGroupingMaster };
export default AppUserGroupingView;
