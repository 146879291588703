import { Model } from "react3l-common";
import { Field } from "react3l-decorators";
import { FileTemplateInput } from "models/FileTemplateInput";
import { Organization } from "models/Organization";

export class FileTemplate extends Model {
  @Field(Number)
  public id?: number;
  @Field(Number)
  public organizationId?: number;
  @Field(Number)
  public fileId?: number;
  @Field(String)
  public name?: string;
  @Field(String)
  public url?: string;
  @Field(String)
  public fileName?: string;
  @Field(Number)
  public selfDefineInputId?: number;
  @Field(Number)
  public statusId?: number = 1;
  @Field(String)
  public sample?: string;
  @Field(Number)
  public creatorId?: number;
  @Field(Boolean)
  public isError?: boolean;
  public organization?: Organization;
  public file?: FileModel;
  public fileTemplateInputs?: FileTemplateInput[];
}

export interface FileModel {
  id?: number;
  fileId?: string | number;
  name?: string;
  url?: string;
  appUserId?: number;
  rowId?: string;
  content?: string;
  mimeType?: string;
  isFile?: boolean;
  key?: any;
  path?: string;
  level?: number;
  isDelete?: boolean;
  clearAction?: (fileId: string | number) => void;
  handleInput?: (e: any) => void;
}
