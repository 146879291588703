/* begin general import */
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { ColumnProps } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import { Action, ActionFilter } from "models/Action";
import { FieldModel, FieldModelFilter } from "models/FieldModel";
import { Menu } from "models/Menu";
/* end general import */
/* begin individual import */
import { Permission } from "models/Permission";
import {
  PermissionActionMapping,
  PermissionActionMappingFilter,
} from "models/PermissionActionMapping";
import {
  PermissionContent,
  PermissionContentFilter,
} from "models/PermissionContent";
import { PermissionOperatorFilter } from "models/PermissionOperator";
import React, { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import {
  FormItem,
  LayoutCell,
  LayoutHeader,
  OneLineText,
  Select,
} from "react3l-ui-library";
import { Reducer } from "redux";
import { roleRepository } from "repositories/role-repository";
import { utilService } from "services/common-services/util-service";
import { ModelAction } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import {
  FilterAction,
  filterReducer,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { tableService } from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import FieldInput from "../FieldInput/FieldInput";
/* end individual import */

export function usePermisionActionMappingHook(
  model: Permission,
  setModel?: React.Dispatch<ModelAction<Permission>>,
  loadMenu?: boolean,
  setLoadMenu?: React.Dispatch<SetStateAction<boolean>>,
  isCreatePermission?: boolean
) {
  const [translate] = useTranslation();
  const [listAction, setListAction] = React.useState<Action[]>([]);
  const [selectedList, setSelectedList] = React.useState<Action[]>([]);

  const [filter] = React.useReducer<
    React.Reducer<
      PermissionActionMappingFilter,
      FilterAction<PermissionActionMappingFilter>
    >
  >(filterReducer, new PermissionActionMappingFilter());

  const { handleChangeAllField } = fieldService.useField(model, setModel);

  const { list } = listService.useLocalList(
    model?.permissionActionMappings,
    filter
  );

  React.useEffect(() => {
    if (
      ((model.id && !isCreatePermission) ||
        (!model.id && isCreatePermission)) &&
      model.permissionActionMappings &&
      model.permissionActionMappings.length > 0
    ) {
      let tempPermissionActionMappings = model.permissionActionMappings
        ? [...model.permissionActionMappings]
        : [];
      tempPermissionActionMappings =
        tempPermissionActionMappings?.length > 0 &&
        tempPermissionActionMappings?.filter(
          (permission) => permission?.actionId !== 0
        );
      const newList =
        tempPermissionActionMappings?.length > 0 &&
        tempPermissionActionMappings?.map((mapping) => {
          if (mapping?.id) {
            mapping.actionId = mapping.id;
          } else {
            mapping = {
              ...new PermissionActionMapping(),
              id: mapping.actionId,
              actionId: mapping.actionId,
              isLocked: mapping.isLocked,
            };
          }
          return mapping;
        });
      setSelectedList([...newList]);
    } else {
      setSelectedList([]);
    }
  }, [isCreatePermission, loadMenu, model.id, model.permissionActionMappings]);

  React.useEffect(() => {
    if (
      (isCreatePermission && loadMenu && model.menuId) ||
      (!isCreatePermission && model.menuId)
    ) {
      const isLockArrId: number[] = [];
      model?.permissionActionMappings?.forEach((actionMapping) => {
        if (actionMapping.isLocked) {
          isLockArrId.push(actionMapping.actionId);
        }
      });
      roleRepository.getMenu(model.menuId).subscribe((res: Menu) => {
        const list = res.actions.map((item: any) => {
          item.key = item.id;
          if (isLockArrId.includes(item.id)) {
            item.isLocked = true;
          }
          return item;
        });
        setListAction(list);
      });

      if (isCreatePermission) {
        setLoadMenu(false);
      }
    }
  }, [
    isCreatePermission,
    loadMenu,
    model.menuId,
    model?.permissionActionMappings,
    setLoadMenu,
  ]);

  const selectedRowKeys = React.useMemo(() => {
    return selectedList && selectedList.length > 0
      ? selectedList.map((t: Action) => t?.id)
      : [];
  }, [selectedList]);

  const rowSelection: TableRowSelection<PermissionActionMapping> =
    React.useMemo(
      () => ({
        preserveSelectedRowKeys: true,
        onSelect: (record: PermissionActionMapping, selected: boolean) => {
          if (selected) {
            selectedList.push(record);
            setSelectedList([...selectedList]);
            handleChangeAllField({
              ...model,
              permissionActionMappings: selectedList,
            });
          } else {
            setSelectedList(
              selectedList.filter((t: any) => {
                return t.id ? t.id !== record.id : t.key !== record.key;
              })
            );
            handleChangeAllField({
              ...model,
              permissionActionMappings: selectedList,
            });
          }
        }, // single selection

        onChange: (...[selectedRowKeys, selectedRows]) => {
          if (selectedList?.length === 0) {
            setSelectedList([...selectedRows]);
            handleChangeAllField({
              ...model,
              permissionActionMappings: [...selectedRows],
            });
            // setPermissionActionMappings([...selectedRows]);
            return;
          } // if list empty, add all selectedRows to list

          const mapper: Record<any, number> = {}; // create mapper from filter
          selectedRowKeys.forEach((key: any) => {
            mapper[key] = 0;
          });
          const mergeList = [...selectedList, ...selectedRows].filter(
            (item) => typeof item !== "undefined"
          ); // merge old list with new selectedRows
          const filterList: any[] = [];
          mergeList.forEach((item) => {
            if (item !== undefined) {
              const itemId = item?.id ? item?.id : item?.key;
              if (mapper[itemId] === 0) {
                filterList.push(item);
                mapper[itemId] = mapper[itemId] + 1;
              }
            }
          }); // filter item which its key contained in selectedRowKeys
          setSelectedList([...filterList]);
          handleChangeAllField({
            ...model,
            permissionActionMappings: [...filterList],
          });
          // setPermissionActionMappings([...filterList]);
        }, // multi selection
        getCheckboxProps: (record) => ({
          disabled: record?.isLocked,
        }), // pass external control for disabling selection
        type: "checkbox", // selection type
        selectedRowKeys, // selectedRowKey
      }),
      [selectedRowKeys, selectedList, handleChangeAllField, model]
    );

  const [actionFilter] = React.useReducer<
    Reducer<ActionFilter, FilterAction<ActionFilter>>
  >(filterReducer, new PermissionActionMappingFilter());
  // const { invokeChange: loadingList } = listService.useLocalList(listAction);

  const permissionActionMappingColumns = React.useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("permissions.actions")}
          />
        ),
        key: nameof(listAction[0].name),
        dataIndex: nameof(listAction[0].name),
        width: 200,
        render(...params: [string, Action, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
    ],
    [listAction, translate]
  );

  return {
    filter: actionFilter,
    permissionActionMappingList: listAction,
    permissionActionMappingRowSelection: rowSelection,
    permissionActionMappings: list,
    permissionActionMappingColumns,
  };
}

export function usePermisionContentHook(
  model: Permission,
  setModel?: React.Dispatch<ModelAction<Permission>>
) {
  const [translate] = useTranslation();

  const [permissionContentFilter, dispatchFilter] = React.useReducer<
    React.Reducer<
      PermissionContentFilter,
      FilterAction<PermissionContentFilter>
    >
  >(filterReducer, new PermissionContentFilter());

  const { handleChangeListField, handleChangeAllField } = fieldService.useField(
    model,
    setModel
  );

  const [resetIdFieldInput, setResetIdFieldInput] =
    React.useState<boolean>(false);

  const { list: permissionContents } = listService.useLocalList(
    model?.permissionContents,
    permissionContentFilter
  );

  const { handleAddRow, handleDeleteRow } = tableService.useContentTable(
    PermissionContentFilter,
    PermissionContent,
    permissionContents,
    handleChangeListField({ fieldName: "permissionContents" }),
    dispatchFilter
  );

  const handleLocalDelete = React.useCallback(
    (content) => () => {
      handleDeleteRow(content?.key);
    },
    [handleDeleteRow]
  );

  const handleChangeField = React.useCallback(
    (index: number) => (value: any, object?: any) => {
      const newModel = [...permissionContents];
      newModel[index]["field"] = object;
      newModel[index]["fieldId"] = value;
      newModel[index]["permissionOperator"] = undefined;
      newModel[index]["permissionOperatorId"] = undefined;
      newModel[index]["value"] = undefined;
      newModel[index]["masterData"] = undefined;
      newModel[index]["masterDataId"] = undefined;
      handleChangeAllField({ ...model, permissionContents: newModel });
    },
    [handleChangeAllField, model, permissionContents]
  );
  const handleChangePermissionOperator = React.useCallback(
    (index: number) => (value: any, object?: any) => {
      const newModel = [...permissionContents];
      newModel[index]["permissionOperator"] = object;
      newModel[index]["permissionOperatorId"] = value;
      newModel[index]["value"] = undefined;
      newModel[index]["masterData"] = undefined;
      newModel[index]["masterDataId"] = undefined;
      setResetIdFieldInput(true);
      handleChangeAllField({ ...model, permissionContents: newModel });
    },
    [handleChangeAllField, model, permissionContents]
  );

  const permissionContentColumns: ColumnProps<PermissionContent>[] =
    React.useMemo(
      () => [
        {
          title: (
            <LayoutHeader
              orderType="center"
              title={translate("permissions.permissionContents.field")}
            />
          ),
          width: 120,
          key: nameof(permissionContents[0].field),
          dataIndex: nameof(permissionContents[0].field),
          ellipsis: true,
          render(...params: [FieldModel, PermissionContent, number]) {
            const filedFilter = new FieldModelFilter();
            filedFilter.menuId.equal = model.menuId;
            return (
              <LayoutCell
                orderType="center"
                tableSize="lg"
                className="permission-content-field"
              >
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    permissionContents[params[2]],
                    nameof(permissionContents[params[2]].field)
                  )}
                  message={permissionContents[params[2]]?.errors?.field}
                >
                  <Select
                    appendToBody
                    type={0}
                    classFilter={FieldModelFilter}
                    placeHolder={translate(
                      "permissions.permissionContents.field"
                    )}
                    render={(t) => {
                      return t?.translatedName;
                    }}
                    getList={roleRepository.singleListField}
                    onChange={handleChangeField(params[2])}
                    value={params[1]?.field}
                    disabled={params[1]?.isLocked}
                    valueFilter={filedFilter}
                  />
                </FormItem>
              </LayoutCell>
            );
          },
        },
        {
          title: (
            <LayoutHeader
              orderType="center"
              title={translate(
                "permissions.permissionContents.permissionOperator"
              )}
            />
          ),
          ellipsis: true,
          key: nameof(permissionContents[0].permissionOperator),
          width: 120,
          dataIndex: nameof(permissionContents[0].permissionOperator),
          render(...params: [number, PermissionContent, number]) {
            const permissionOperatorFilter = new PermissionOperatorFilter();
            permissionOperatorFilter.fieldTypeId.equal =
              params[1].field?.fieldTypeId;
            return (
              <LayoutCell
                orderType="center"
                tableSize="lg"
                className="permission-content-field"
              >
                {params[1].permissionOperator?.name === "UserId" &&
                params[0] === 101 ? (
                  "="
                ) : (
                  <>
                    {permissionContents[params[2]]?.fieldId &&
                    permissionContents[params[2]]?.field ? (
                      <FormItem
                        validateStatus={utilService.getValidateStatus(
                          permissionContents[params[2]],
                          nameof(
                            permissionContents[params[2]].permissionOperator
                          )
                        )}
                        message={
                          permissionContents[params[2]]?.errors
                            ?.permissionOperator
                        }
                      >
                        <Select
                          appendToBody
                          valueFilter={permissionOperatorFilter}
                          type={0}
                          classFilter={FieldModelFilter}
                          placeHolder={translate(
                            "permissions.permissionContents.permissionOperator"
                          )}
                          getList={roleRepository.singleListPermissionOperator}
                          onChange={handleChangePermissionOperator(params[2])}
                          value={params[1]?.permissionOperator}
                          disabled={params[1]?.isLocked}
                        />
                      </FormItem>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </LayoutCell>
            );
          },
        },
        {
          title: (
            <LayoutHeader
              orderType="center"
              title={translate("permissions.permissionContents.value")}
            />
          ),
          key: nameof(permissionContents[0].value),
          width: 120,
          ellipsis: true,
          dataIndex: nameof(permissionContents[0].value),
          render(...params: [string, PermissionContent, number]) {
            return (
              <LayoutCell
                orderType="center"
                tableSize="lg"
                className="permission-content-field"
              >
                {permissionContents[params[2]]?.fieldId &&
                permissionContents[params[2]]?.permissionOperatorId &&
                permissionContents[params[2]]?.field &&
                permissionContents[params[2]]?.permissionOperator ? (
                  <FormItem
                    validateStatus={utilService.getValidateStatus(
                      permissionContents[params[2]],
                      nameof(permissionContents[params[2]].value)
                    )}
                    message={permissionContents[params[2]]?.errors?.value}
                  >
                    <FieldInput
                      value={params[0]}
                      index={params[2]}
                      record={params[1]}
                      contents={permissionContents}
                      setContents={handleChangeListField({
                        fieldName: "permissionContents",
                      })}
                      disabled={
                        params[1].fieldId === undefined || params[1]?.isLocked
                      }
                      resetValueIdFieldInput={resetIdFieldInput}
                      setResetValueIdFieldInput={setResetIdFieldInput}
                    />
                  </FormItem>
                ) : (
                  <></>
                )}
              </LayoutCell>
            );
          },
        },
        {
          title: (
            <LayoutHeader
              orderType="center"
              title={translate("general.actions.label")}
            />
          ),
          key: "action",
          dataIndex: nameof(permissionContents[0].key),
          fixed: "right",
          width: 60,
          align: "center",
          render(...params: [string, PermissionContent, number]) {
            return (
              !params[1]?.isLocked && (
                <TrashCan16
                  color="red"
                  onClick={handleLocalDelete(params[1])}
                />
              )
            );
          },
        },
      ],
      [
        translate,
        permissionContents,
        model.menuId,
        handleChangeField,
        handleChangePermissionOperator,
        handleChangeListField,
        resetIdFieldInput,
        handleLocalDelete,
      ]
    );

  return {
    permissionContentFilter,
    permissionContentList: permissionContents,
    permissionContents,
    permissionContentColumns,
    handleAddPermissionContent: handleAddRow,
  };
}
