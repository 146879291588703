/* begin general import */
import Add16 from "@carbon/icons-react/es/add/16";
import DocumentPdf16 from "@carbon/icons-react/es/document--pdf/16";
import Download16 from "@carbon/icons-react/es/download/16";
import OverflowMenuHorizontal24 from "@carbon/icons-react/es/overflow-menu--horizontal/24";
import SettingsAdjust16 from "@carbon/icons-react/es/settings--adjust/16";
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import Upload16 from "@carbon/icons-react/es/upload/16";
import { Dropdown, Menu, Spin } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import { PROVINCE_ROUTE } from "config/route-consts";
import { formatNumber } from "helpers/number";
import { Province, ProvinceFilter } from "models/Province";
import { Status } from "models/Status";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IdFilter } from "react3l-advanced-filters";
import {
  ActionBarComponent,
  AdvanceEnumFilterMaster,
  AdvanceIdFilterMaster,
  Button,
  LayoutCell,
  LayoutHeader,
  OneLineText,
  Pagination,
  StandardTable,
  StatusLine,
  TagFilter,
} from "react3l-ui-library";
/* end general import */
/* begin filter import */
import InputSearch from "react3l-ui-library/build/components/Input/InputSearch";
/* end filter import */
/* begin individual import */
import { provinceRepository } from "repositories/province-repository";

import { filterService } from "services/page-services/filter-service";

import { detailService } from "services/page-services/detail-service";
import { importExportService } from "services/page-services/import-export-service";
import { listService } from "services/page-services/list-service";
import { masterService } from "services/page-services/master-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import ProvinceDetailDrawer from "../ProvinceDetail/ProvinceDetailDrawer";
import ProvinceAdvanceFilter from "./ProvinceAdvanceFilter";
import { Region, RegionFilter } from "models/Region";
/* end individual import */

function ProvinceMaster() {
  const [translate] = useTranslation();

  const [modelFilter, dispatch] = queryStringService.useQueryString(
    ProvinceFilter,
    { skip: 0, take: 10 }
  );

  const [visible, setVisible] = React.useState<boolean>(false);

  // const importButtonRef: React.LegacyRef<HTMLInputElement> =
  //   useRef<HTMLInputElement>();

  const { handleListExport, handleExportTemplateList, loading } =
    importExportService.useExport();

  const {
    value: filter,
    handleChangeSelectFilter,
    handleChangeAllFilter,
    handleChangeInputSearch,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleResetList, handleLoadList } =
    listService.useList(
      provinceRepository.list,
      provinceRepository.count,
      filter,
      dispatch
    );

  const {
    handleImportList,
    handleClick,
    ref: importButtonRef,
  } = importExportService.useImport(handleResetList);

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const {
    handleAction,
    handleBulkAction,
    canBulkAction,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
  } = listService.useRowSelection(
    provinceRepository.delete,
    provinceRepository.bulkDelete,
    null,
    null,
    null,
    handleResetList
  );

  const { handleDeleteItem } = masterService.useMasterAction(
    PROVINCE_ROUTE,
    handleAction
  );

  const {
    model,
    dispatch: dispatchModal,
    isOpenDetailModal,
    handleOpenDetailModal,
    handleCloseDetailModal,
    handleSaveModel,
    loadingModel,
    handleChangeSingleField,
    handleChangeSelectField,
    handleChangeDateField,
  } = detailService.useDetailModal(
    Province,
    provinceRepository.get,
    provinceRepository.save,
    handleLoadList
  );

  const menuAction = React.useCallback(
    (id: number, province: Province) => (
      <Menu>
        <Menu.Item key="1">
          <div
            className="ant-action-menu"
            onClick={() => handleOpenDetailModal(id)}
          >
            {translate("general.actions.view")}
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div
            className="ant-action-menu"
            onClick={() => handleOpenDetailModal(id)}
          >
            {translate("general.actions.edit")}
          </div>
        </Menu.Item>
        {!province.used && (
          <Menu.Item key="3">
            <div
              className="ant-action-menu"
              onClick={handleDeleteItem(province)}
            >
              {translate("general.actions.delete")}
            </div>
          </Menu.Item>
        )}
      </Menu>
    ),
    [translate, handleDeleteItem, handleOpenDetailModal]
  );

  const columns: ColumnProps<Province>[] = useMemo(
    () => [
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "code"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("provinces.code")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].code),
        dataIndex: nameof(list[0].code),
        sorter: true,
        sortOrder: getAntOrderType<Province, ProvinceFilter>(
          filter,
          nameof(list[0].code)
        ),
        ellipsis: true,
        render(...params: [string, Province, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "name"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("provinces.name")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        sorter: true,
        sortOrder: getAntOrderType<Province, ProvinceFilter>(
          filter,
          nameof(list[0].name)
        ),
        ellipsis: true,
        render(...params: [string, Province, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "region"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("provinces.region")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].region),
        dataIndex: nameof(list[0].region),
        sorter: true,
        sortOrder: getAntOrderType<Province, ProvinceFilter>(
          filter,
          nameof(list[0].region)
        ),
        ellipsis: true,
        render(...params: [Region, Province, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]?.name} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "priority"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("provinces.priority")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].priority),
        dataIndex: nameof(list[0].priority),
        sorter: true,
        sortOrder: getAntOrderType<Province, ProvinceFilter>(
          filter,
          nameof(list[0].priority)
        ),
        ellipsis: true,
        render(...params: [number, Province, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={formatNumber(params[0])} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "status"
          );
          return (
            <LayoutHeader
              orderType="left"
              title={translate("provinces.status")}
              sortedColumn={sortedColumn}
              isSorter
            />
          );
        },
        key: nameof(list[0].status),
        dataIndex: nameof(list[0].status),
        sorter: true,
        sortOrder: getAntOrderType<Province, ProvinceFilter>(
          filter,
          nameof(list[0].status)
        ),
        ellipsis: true,
        render(status: Status) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <StatusLine value={status?.name} color={status?.color} />
            </LayoutCell>
          );
        },
      },

      {
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, province: Province) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, province)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal24 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, list, filter, menuAction]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <Spin spinning={loading}>
        <div className="page-content">
          <PageHeader
            title={translate("provinces.master.subHeader")}
            breadcrumbItems={[
              translate("provinces.master.header"),
              translate("provinces.master.subHeader"),
            ]}
          />
          <div className="page page-master m-t--lg m-l--sm m-r--xxl m-b--xxs">
            <div className="page-master__title p-l--sm p-t--sm p-r--sm p-b--lg">
              {translate("provinces.master.title")}
            </div>
            <div className="page-master__content">
              <div className="page-master__tag-filter">
                <TagFilter
                  value={filter}
                  translate={translate}
                  keyTranslate={"provinces"}
                  handleChangeFilter={handleChangeAllFilter}
                  onClear={(value: any) => {
                    return 0;
                  }}
                />
              </div>
              {(!selectedRowKeys || selectedRowKeys?.length === 0) && (
                <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                  <div className="page-master__filter d-flex align-items-center justify-content-start">
                    <div className="">
                      <AdvanceIdFilterMaster
                        value={filter[nameof(list[0].regionId)]["equal"]}
                        placeHolder={translate("provinces.placeholder.region")}
                        classFilter={RegionFilter}
                        onChange={handleChangeSelectFilter({
                          fieldName: nameof(list[0].region),
                          fieldType: "equal",
                          classFilter: IdFilter,
                        })}
                        getList={provinceRepository.filterListRegion}
                        label={translate("provinces.region")}
                      />
                    </div>
                    <div className="">
                      <AdvanceEnumFilterMaster
                        value={filter[nameof(list[0].statusId)]["equal"]}
                        getList={provinceRepository.filterListStatus}
                        onChange={handleChangeSelectFilter({
                          fieldName: nameof(list[0].status),
                          fieldType: "equal",
                          classFilter: IdFilter,
                        })}
                        label={translate("provinces.status")}
                      />
                    </div>
                  </div>
                  <div className="page-master__filter-action-search d-flex align-items-center">
                    <Button
                      type="icon-only-ghost"
                      icon={<SettingsAdjust16 />}
                      onClick={() => setVisible(true)}
                      className="btn--xl"
                    />
                    <InputSearch
                      value={filter.search}
                      classFilter={ProvinceFilter}
                      placeHolder={translate("general.placeholder.search")}
                      onChange={handleChangeInputSearch}
                      animationInput={filter?.search ? false : true}
                    />
                  </div>

                  <div className="page-master__actions  d-flex align-items-center justify-content-start">
                    <div className="page-master__filter-action d-flex align-items-center">
                      <input
                        ref={importButtonRef}
                        type="file"
                        style={{ display: "none" }}
                        id="master-import"
                        onChange={handleImportList(provinceRepository.import)}
                        onClick={handleClick}
                      />
                      <Button
                        type="icon-only-ghost"
                        icon={<Download16 />}
                        onClick={handleListExport(
                          filter,
                          provinceRepository.export
                        )}
                        className="btn--xl"
                      />
                      <Button
                        type="icon-only-ghost"
                        icon={<Upload16 />}
                        onClick={() => {
                          importButtonRef.current.click();
                        }}
                        className="btn--xl"
                      />
                      <Button
                        type="icon-only-ghost"
                        icon={<DocumentPdf16 />}
                        onClick={handleExportTemplateList(
                          provinceRepository.exportTemplate
                        )}
                        className="btn--xl"
                      />
                      <Button
                        type="primary"
                        className="btn--lg"
                        icon={<Add16 />}
                        onClick={() => handleOpenDetailModal(null)}
                      >
                        {translate("general.actions.create")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="page-master__content-table">
              <ActionBarComponent
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                translateTitleCancelButton={translate("general.actions.close")}
              >
                <Button
                  icon={<TrashCan16 />}
                  type="ghost-primary"
                  className="btn--lg"
                  disabled={!canBulkAction}
                  onClick={() => handleBulkAction(selectedRowKeys)}
                >
                  {translate("general.actions.delete")}
                </Button>
              </ActionBarComponent>
              <StandardTable
                rowKey={nameof(list[0].id)}
                columns={columns}
                dataSource={list}
                isDragable={true}
                tableSize={"md"}
                onChange={handleTableChange}
                spinning={loadingList}
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
              />

              <Pagination
                skip={filter?.skip}
                take={filter?.take}
                total={count}
                onChange={handlePagination}
                //canChangePageSize={false}
              />
            </div>
          </div>
        </div>
      </Spin>
      {visible && (
        <ProvinceAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )}
      <ProvinceDetailDrawer
        model={model}
        visible={isOpenDetailModal}
        handleSave={handleSaveModel}
        handleCancel={handleCloseDetailModal}
        handleChangeSingleField={handleChangeSingleField}
        handleChangeSelectField={handleChangeSelectField}
        handleChangeDateField={handleChangeDateField}
        dispatch={dispatchModal}
        loading={loadingModel}
        visibleFooter={true}
      />
    </>
  );
}
export default ProvinceMaster;
