import Add16 from "@carbon/icons-react/es/add/16";
import Add24 from "@carbon/icons-react/es/add/24";
import OverflowMenuHorizontal16 from "@carbon/icons-react/es/overflow-menu--horizontal/16";
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { Card, Col, Dropdown, Menu, Row, Spin, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table/Column";
import PageHeader from "components/PageHeader/PageHeader";
import { APP_USER_GROUPING_ROUTE } from "config/route-consts";
import { AppUser } from "models/AppUser";
import { AppUserGrouping, AppUserGroupingFilter } from "models/AppUserGrouping";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionBarComponent,
  AdvanceEnumFilterMaster,
  AdvanceTreeFilter,
  Button,
  LayoutCell,
  OneLineText,
  Pagination,
  StandardTable,
  StatusLine,
  TagFilter,
} from "react3l-ui-library";
import { IdFilter } from "react3l-advanced-filters";
import InputSearch from "react3l-ui-library/build/components/Input/InputSearch";
import LayoutHeader from "react3l-ui-library/build/components/StandardTable/LayoutHeader";
import { appUserGroupingRepository } from "repositories/app-user-grouping-repository";
import { finalize } from "rxjs";

import { utilService } from "services/common-services/util-service";
import { filterService } from "services/page-services/filter-service";
import { masterService } from "services/page-services/master-service";
import { queryStringService } from "services/page-services/query-string-service";
import { tableService } from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import AppUserGroupingDetailDrawer from "../AppUserGroupingDetail/AppUserGroupingDetailDrawer";
import "./AppUserGroupingMaster.scss";
import {
  AppUserGroupingMappingModal,
  useAppUserGroupingMappingHook,
} from "./AppUserGroupingMasterHook/AppUserGroupingMappingModal";
import {
  useRowSelection,
  useAppUserGroupingTreeMasterHook,
} from "./AppUserGroupingMasterHook/AppUserGroupingMasterHook";
import {
  AppUserGroupingAppUserMappingModal,
  useAppUserGroupingAppUserHook,
} from "./AppUserGroupingMasterHook/AppUserGroupingAppUserMappingModal";
import AppUserGroupingTree from "./AppUserGroupingTree/AppUserGroupingTree";
import { Status } from "models/Status";
import { OrganizationFilter } from "models/Organization";

function AppUserGroupingTreeMaster() {
  const [translate] = useTranslation();

  const [modelFilter, dispatch] = queryStringService.useQueryString(
    AppUserGroupingFilter,
    { skip: 0, take: 10 }
  );
  const [internalExpandedKeys, setInternalExpandedKeys] = React.useState<
    number[]
  >([]);
  const [isCallListStart, setIsCallListStart] = React.useState<boolean>(true);
  const [loadingTree, setLoadingTree] = React.useState<boolean>(false); // loading on tree
  const [loadingTable, setLoadingTable] = React.useState<boolean>(false); // loading on table
  const [listTree, setListTree] = React.useState<AppUserGrouping[]>([]); // list is used to build tree
  const [treeDataList] = utilService.buildTree<AppUserGrouping>(listTree);

  const { value: filter, handleChangeAllFilter } = filterService.useFilter(
    modelFilter,
    dispatch
  );

  // Load ra các node gốc ban đầu của tree folder bằng cách lọc theo level = 1
  React.useEffect(() => {
    if (isCallListStart) {
      setLoadingTree(true);
      appUserGroupingRepository
        .list({
          ...filter,
          level: { equal: 1 },
          skip: 0,
          take: 100000,
        })
        .pipe(finalize(() => setLoadingTree(false)))
        .subscribe((res: any) => {
          setListTree([...res]);
        });

      setIsCallListStart(false);
    }
  }, [filter, isCallListStart, listTree]);

  const {
    isActive,
    currentNode,
    handleClickIconNode,
    handleClickTitleNode,
    onClickDeleteIcon,
    setCurrentNode,
    isOpenDetailModal,
    loadingModel,
    handleGoCreate,
    handleSaveModel,
    handleCloseDetailModal,
    model,
    handleChangeSingleField,
    handleChangeTreeField,
    handleChangeAllField,
    handleChangeSelectField,
    filterAppUser,
    setFilterAppUser,
    handleLoadList,
    handleChangeTreeAppUserGroupingFilter,
    handleChangeInputSearchAppUserGroupingFilter,
    handleChangeAppUserFilter,
    handleOpenDetailModal,
    list,
    count,
    currentOrganization,
  } = useAppUserGroupingTreeMasterHook(
    translate,
    setListTree,
    loadingTable,
    loadingTree,
    setLoadingTable,
    setLoadingTree,
    listTree,
    internalExpandedKeys,
    setInternalExpandedKeys,
    filter,
    handleChangeAllFilter,
    setIsCallListStart
  );

  const {
    value: valueFilterAppUser,
    handleChangeSelectFilter,
    handleChangeAllFilter: handleChangeAllFilterAppUser,
  } = filterService.useFilter(filterAppUser, setFilterAppUser);

  /* AppUser table */

  const {
    handleTableChange: handleTableChangeAppUser,
    handlePagination: handlePaginationAppUser,
  } = tableService.useTable(
    valueFilterAppUser as any,
    handleChangeAllFilterAppUser
  );

  const {
    handleAction,
    handleBulkAction,
    canBulkAction,
    rowSelection: rowSelectionAppUser,
    selectedRowKeys,
    setSelectedRowKeys,
  } = useRowSelection(
    appUserGroupingRepository.removeAppUser,
    appUserGroupingRepository.bulkRemoveAppUser,
    null,
    null,
    null,
    handleLoadList,
    null,
    currentNode
  );

  const { handleDeleteItem } = masterService.useMasterAction(
    APP_USER_GROUPING_ROUTE,
    handleAction
  );

  const {
    visible,
    list: listMapping,
    loading: loadingAppUserModal,
    appUserFilter,
    total,
    handleOpenModal,
    handleSave,
    handleCloseModal,
    rowSelection,
    handleFilter,
    handleChangePagination,
    handleSelectFilter,
  } = useAppUserGroupingAppUserHook(
    currentNode,
    setCurrentNode,
    handleLoadList,
    filterAppUser,
    list
  );

  /* AppUserGrouping Modal*/
  const {
    visible: visibleAppUserGrouping,
    list: listMappingAppUserGrouping,
    loading: loadingAppUserGroupingModal,
    appUserGroupingFilter,
    setAppUserGroupingFilter,
    total: totalAppUserGrouping,
    handleOpenModal: handleOpenModalAppUserGrouping,
    handleCloseModal: handleCloseModalAppUserGrouping,
    currentAppUser,
    handleGetItemList,
  } = useAppUserGroupingMappingHook();

  const {
    handleTableChange: handleTableChangeAppUserGrouping,
    handlePagination: handlePaginationAppUserGrouping,
  } = tableService.useTable(
    appUserGroupingFilter,
    setAppUserGroupingFilter,
    handleGetItemList
  );

  const menuAction = React.useCallback(
    (id: number, appUser: AppUser) => (
      <Menu>
        <Menu.Item key="1">
          <Tooltip title={translate("general.actions.view")}>
            <div
              className="ant-action-menu"
              onClick={handleOpenModalAppUserGrouping(id, appUser)}
            >
              Xem nhóm người dùng
            </div>
          </Tooltip>
        </Menu.Item>
        {appUser?.isChild && (
          <Menu.Item key="3">
            <Tooltip title={translate("general.actions.delete")}>
              <div
                className="ant-action-menu"
                onClick={handleDeleteItem({
                  appUserGroupingId: currentNode?.id,
                  appUser,
                })}
              >
                {translate("general.actions.delete")}
              </div>
            </Tooltip>
          </Menu.Item>
        )}
      </Menu>
    ),
    [currentNode, handleDeleteItem, handleOpenModalAppUserGrouping, translate]
  );

  const columns: ColumnProps<AppUser>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("appUserGroupings.appUsers.username")}
          />
        ),
        key: "username",
        dataIndex: "username",
        ellipsis: true,
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("appUserGroupings.appUsers.displayName")}
          />
        ),
        key: "displayName",
        dataIndex: "displayName",

        ellipsis: true,
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("appUserGroupings.appUsers.isChild")}
          />
        ),
        key: "isChild",
        dataIndex: "isChild",

        render(...params: [boolean, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <div
                className={
                  params[0] === true
                    ? "status__icon-active m-l--sm m-r--xxs"
                    : "status__icon-inactive m-l--sm m-r--xxs"
                }
              />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("appUserGroupings.appUsers.status")}
          />
        ),
        key: "status",
        dataIndex: "status",

        ellipsis: true,
        render(...params: [Status, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="sm">
              <StatusLine value={params[0]?.name} color={params[0]?.color} />
            </LayoutCell>
          );
        },
      },
      {
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 150,
        align: "center",
        render(id: number, appUser: AppUser) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, appUser)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal16 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [list, menuAction, translate]
  );

  return (
    <>
      <div className="page-content">
        <PageHeader
          title={translate("appUserGroupings.master.subHeader")}
          breadcrumbItems={[
            translate("appUserGroupings.master.header"),
            translate("appUserGroupings.master.subHeader"),
          ]}
        />
        <div className="page page-master app-user-grouping__master m-l--sm m-r--xxl m-b--xxs">
          <Row>
            <Col lg={10} className="app-user-grouping__card p-l--sm p-r--sm">
              <Card headStyle={{ fontWeight: "bold" }}>
                <div className="d-flex justify-content-between">
                  <div className="app-user-grouping__card__title m-b--xxs">
                    {translate("appUserGroupings.master.title")}
                  </div>
                  <span
                    className="m-l--xxl"
                    onClick={handleGoCreate(undefined)}
                  >
                    <Add24 />
                  </span>
                </div>
                <div className="d-flex m-b--xxs">
                  <AdvanceTreeFilter
                    placeHolder={translate(
                      "appUserGroupings.placeholder.organization"
                    )}
                    classFilter={OrganizationFilter}
                    onChange={handleChangeTreeAppUserGroupingFilter}
                    checkStrictly={true}
                    item={modelFilter["organizationValue"]}
                    getTreeData={
                      appUserGroupingRepository.filterListOrganization
                    }
                    label={translate("appUserGroupings.organization")}
                  />
                </div>
                <div className="d-flex m-b--xxs">
                  <InputSearch
                    valueFilter={filter}
                    value={filter?.search}
                    classFilter={AppUserGroupingFilter}
                    placeHolder={translate("appUserGroupings.placeholder.name")}
                    onChange={handleChangeInputSearchAppUserGroupingFilter}
                    className="app-user-grouping__input-search"
                    animationInput={false}
                  />
                </div>

                <AppUserGroupingTree
                  tree={treeDataList}
                  onShowChildOfNode={handleClickIconNode}
                  onShowDataTable={handleClickTitleNode}
                  currentNode={currentNode}
                  setCurrentNode={setCurrentNode}
                  onAdd={handleGoCreate}
                  onDelete={onClickDeleteIcon}
                  internalExpandedKeys={internalExpandedKeys}
                  setInternalExpandedKeys={setInternalExpandedKeys}
                  listTree={listTree}
                  onEdit={handleOpenDetailModal}
                />
              </Card>
            </Col>
            <Col lg={14}>
              {currentNode?.id && isActive && (
                <Spin spinning={loadingTable}>
                  <div className="page-master__tag-filter">
                    <TagFilter
                      value={filterAppUser}
                      translate={translate}
                      keyTranslate={"appUserGroupings"}
                      handleChangeFilter={handleChangeAllFilterAppUser}
                      onClear={(value: any) => {
                        return 0;
                      }}
                      exceptField={["dependencyTypeId"]}
                    />
                  </div>
                  {(!selectedRowKeys || selectedRowKeys?.length === 0) && (
                    <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between m-t--xxs">
                      <div className="page-master__filter d-flex align-items-center justify-content-start">
                        <div className="d-flex align-items-center">
                          <AdvanceEnumFilterMaster
                            value={filterAppUser?.dependencyTypeId?.equal}
                            getList={
                              appUserGroupingRepository.filterListDependencyType
                            }
                            onChange={handleChangeSelectFilter({
                              fieldName: "dependencyType",
                              fieldType: "equal",
                              classFilter: IdFilter,
                            })}
                            label={translate(
                              "appUserGroupings.appUsers.dependencyTypeId"
                            )}
                            height={120}
                          />
                        </div>
                        <div className="d-flex align-items-center">
                          <AdvanceEnumFilterMaster
                            value={filterAppUser?.statusId?.equal}
                            getList={appUserGroupingRepository.filterListStatus}
                            onChange={handleChangeSelectFilter({
                              fieldName: "status",
                              fieldType: "equal",
                              classFilter: IdFilter,
                            })}
                            label={translate("appUserGroupings.status")}
                            height={90}
                          />
                        </div>
                        <div>
                          <InputSearch
                            value={filterAppUser?.search}
                            classFilter={AppUserGroupingFilter}
                            placeHolder={translate(
                              "appUserGroupings.placeholder.searchAppUser"
                            )}
                            onChange={handleChangeAppUserFilter}
                            className="app-user-grouping__input-search"
                            animationInput={filter?.search ? false : true}
                          />
                        </div>
                      </div>

                      <div className="page-master__actions  d-flex align-items-center justify-content-start">
                        <div className="page-master__filter-action d-flex align-items-center">
                          <Button
                            type="primary"
                            className="btn--lg"
                            icon={<Add16 />}
                            disabled={
                              loadingTable ||
                              loadingTree ||
                              currentNode?.statusId !== 1
                            }
                            onClick={handleOpenModal()}
                          >
                            {translate("appUserGroupings.buttons.add")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="page-master__content-table appUserGrouping_app_user_table">
                    <>
                      <ActionBarComponent
                        selectedRowKeys={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                        translateTitleCancelButton={translate(
                          "general.actions.close"
                        )}
                      >
                        <Button
                          icon={<TrashCan16 />}
                          type="ghost-primary"
                          className="btn--lg"
                          disabled={!canBulkAction}
                          onClick={() => handleBulkAction(selectedRowKeys)}
                        >
                          {translate("general.actions.delete")}
                        </Button>
                      </ActionBarComponent>
                      <StandardTable
                        rowKey={nameof(list[0].id)}
                        columns={columns}
                        dataSource={list}
                        isDragable={true}
                        tableSize={"md"}
                        onChange={handleTableChangeAppUser}
                        rowSelection={rowSelectionAppUser}
                        scroll={{ y: 500 }}
                      />
                      <Pagination
                        skip={filterAppUser?.skip}
                        take={filterAppUser?.take}
                        total={count}
                        onChange={handlePaginationAppUser}
                      />
                    </>
                  </div>
                </Spin>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <AppUserGroupingAppUserMappingModal
        visible={visible}
        handleClose={handleCloseModal}
        handleSave={handleSave}
        handleChangeInputFilter={handleFilter}
        handlePagination={handleChangePagination}
        translate={translate}
        appUserFilter={appUserFilter}
        listAppUser={listMapping}
        countAppUser={total}
        loadList={loadingAppUserModal}
        handleTableChange={handleTableChangeAppUser}
        rowSelection={rowSelection}
        handleSelectFilter={handleSelectFilter}
      />

      <AppUserGroupingMappingModal
        visible={visibleAppUserGrouping}
        handleClose={handleCloseModalAppUserGrouping}
        handlePagination={handlePaginationAppUserGrouping}
        translate={translate}
        appUserGroupingFilter={appUserGroupingFilter}
        listAppUserGrouping={listMappingAppUserGrouping}
        countAppUserGrouping={totalAppUserGrouping}
        loadList={loadingAppUserGroupingModal}
        handleTableChange={handleTableChangeAppUserGrouping}
        setAppUserGroupingFilter={setAppUserGroupingFilter}
        currentAppUser={currentAppUser}
        handleGetItemList={handleGetItemList}
        handleReloadListAppUser={handleLoadList}
      />

      {isOpenDetailModal && (
        <AppUserGroupingDetailDrawer
          model={model}
          currentNode={currentNode}
          visible={isOpenDetailModal}
          handleSave={handleSaveModel}
          handleCancel={handleCloseDetailModal}
          handleChangeSingleField={handleChangeSingleField}
          handleChangeTreeField={handleChangeTreeField}
          handleChangeAllField={handleChangeAllField}
          handleChangeSelectField={handleChangeSelectField}
          loading={loadingModel}
          visibleFooter={true}
          currentOrganization={currentOrganization}
        />
      )}
    </>
  );
}

export default AppUserGroupingTreeMaster;
