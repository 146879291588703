import { Status } from 'models/Status';
import { Model } from 'react3l-common';
import { Field } from 'react3l-decorators';

export class Region extends Model
{
    @Field(Number)
    public id?: number;


    @Field(String)
    public code?: string;


    @Field(String)
    public name?: string;


    @Field(Number)
    public priority?: number;


    @Field(Number)
    public statusId?: number = 1;

    @Field(String)
    public rowId?: string;

    @Field(Boolean)
    public used?: boolean;

    public status?: Status;

}
