/* begin general import */
import { TableRowSelection } from "antd/lib/table/interface";
import { Action, ActionFilter } from "models/Action";
import { ActionTypeFilter } from "models/ActionType";
/* end general import */
/* begin individual import */
import {
  PermissionActionMapping,
  PermissionActionMappingFilter,
} from "models/PermissionActionMapping";
import { Role } from "models/Role";
import React from "react";
import { useTranslation } from "react-i18next";
import { LayoutCell, LayoutHeader, OneLineText } from "react3l-ui-library";
import { Reducer } from "redux";
import { roleRepository } from "repositories/role-repository";
import { ModelAction } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import {
  FilterAction,
  filterReducer,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import nameof from "ts-nameof.macro";
/* end individual import */

export function useGeneralActionHook(
  model: Role,
  setModel?: React.Dispatch<ModelAction<Role>>
) {
  const [reloadActionType, setReloadActionType] =
    React.useState<boolean>(false);
  const [translate] = useTranslation();
  const [listAction, setListAction] = React.useState<Action[]>([]);
  const [selectedList, setSelectedList] = React.useState<Action[]>([]);

  const [filter] = React.useReducer<
    React.Reducer<
      PermissionActionMappingFilter,
      FilterAction<PermissionActionMappingFilter>
    >
  >(filterReducer, new PermissionActionMappingFilter());

  const { handleChangeAllField } = fieldService.useField(model, setModel);

  const { list } = listService.useLocalList(model?.generalActionTypes, filter);

  React.useEffect(() => {
    if (model.generalActionTypes && model.generalActionTypes.length > 0) {
      let tempPermissionActionMappings = model.generalActionTypes
        ? [...model.generalActionTypes].map((item) => {
            return {
              id: item.actionTypeId,
              actionId: item.actionTypeId,
            };
          })
        : [];

      setSelectedList(tempPermissionActionMappings);
    } else setSelectedList([]);
  }, [model.generalActionTypes]);

  React.useEffect(() => {
    if (reloadActionType) {
      setSelectedList([]);
      handleChangeAllField({
        ...model,
        generalActionTypes: [],
      });
      setReloadActionType(false);
    }
  }, [handleChangeAllField, model, reloadActionType]);

  React.useEffect(() => {
    if (model?.siteId) {
      const actionTypeFilter: ActionTypeFilter = {
        ...new ActionTypeFilter(),
        siteId: {
          equal: model.siteId,
        },
      };
      roleRepository
        .singleListActionType(actionTypeFilter)
        .subscribe((res: any[]) => {
          const list = res.map((item: any) => {
            item.key = item.id;
            return item;
          });
          setListAction(list);

          if (!model?.id) {
            setReloadActionType(true);
          }
        });
    }
  }, [model?.id, model.menuId, model.siteId]);

  const selectedRowKeys = React.useMemo(() => {
    return selectedList && selectedList.length > 0
      ? selectedList.map((t: Action) => t?.id)
      : [];
  }, [selectedList]);

  const rowSelection: TableRowSelection<PermissionActionMapping> =
    React.useMemo(
      () => ({
        preserveSelectedRowKeys: true,
        onSelect: (record: PermissionActionMapping, selected: boolean) => {
          if (selected) {
            selectedList.push(record);
            setSelectedList([...selectedList]);
            const tmpgeneralActionTypeList = selectedList.map((item) => {
              return {
                actionTypeId: item?.id,
                roleId: model?.id,
              };
            });
            handleChangeAllField({
              ...model,
              generalActionTypes: tmpgeneralActionTypeList,
            });
          } else {
            const tmpSelectedList = selectedList.filter((t: any) => {
              return t.id ? t.id !== record.id : t.key !== record.key;
            });
            setSelectedList(tmpSelectedList);
            const tmpgeneralActionTypeList = tmpSelectedList.map((item) => {
              return {
                actionTypeId: item?.id,
                roleId: model?.id,
              };
            });
            handleChangeAllField({
              ...model,
              generalActionTypes: tmpgeneralActionTypeList,
            });
          }
        }, // single selection
        onChange: (...[selectedRowKeys, selectedRows]) => {
          if (selectedList?.length === 0) {
            setSelectedList([...selectedRows]);
            const tmpgeneralActionTypeList = selectedRows.map((item) => {
              return {
                actionTypeId: item?.id,
                roleId: model?.id,
              };
            });
            handleChangeAllField({
              ...model,
              generalActionTypes: [...tmpgeneralActionTypeList],
            });
            // setPermissionActionMappings([...selectedRows]);
            return;
          } // if list empty, add all selectedRows to list

          const mapper: Record<any, number> = {}; // create mapper from filter
          selectedRowKeys.forEach((key: any) => {
            mapper[key] = 0;
          });
          const mergeList = [...selectedList, ...selectedRows].filter(
            (item) => typeof item !== "undefined"
          ); // merge old list with new selectedRows
          const filterList: any[] = [];
          mergeList.forEach((item) => {
            if (item !== undefined) {
              const itemId = item?.id ? item?.id : item?.key;
              if (mapper[itemId] === 0) {
                filterList.push(item);
                mapper[itemId] = mapper[itemId] + 1;
              }
            }
          }); // filter item which its key contained in selectedRowKeys
          setSelectedList([...filterList]);
          const tmpgeneralActionTypeList = filterList.map((item) => {
            return {
              actionTypeId: item?.id,
              roleId: model?.id,
            };
          });
          handleChangeAllField({
            ...model,
            generalActionTypes: [...tmpgeneralActionTypeList],
          });
          // setPermissionActionMappings([...filterList]);
        }, // multi selection
        getCheckboxProps: () => ({
          disabled: false,
        }), // pass external control for disabling selection
        type: "checkbox", // selection type
        selectedRowKeys, // selectedRowKey
      }),
      [selectedRowKeys, selectedList, handleChangeAllField, model]
    );

  const [actionFilter] = React.useReducer<
    Reducer<ActionFilter, FilterAction<ActionFilter>>
  >(filterReducer, new PermissionActionMappingFilter());
  // const { invokeChange: loadingList } = listService.useLocalList(listAction);

  const generalActionTypeColumns = React.useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("permissions.actions")}
          />
        ),
        key: nameof(listAction[0].name),
        dataIndex: nameof(listAction[0].name),
        width: 200,
        render(...params: [string, Action, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
    ],
    [listAction, translate]
  );

  return {
    filter: actionFilter,
    generalActionTypeList: listAction,
    generalActionTypeRowSelection: rowSelection,
    generalActionTypes: list,
    generalActionTypeColumns,
  };
}
