/* begin general import */
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { Col, Modal as ModalAnt, Row } from "antd";
import { ColumnProps } from "antd/lib/table";
import { generalLanguageKeys } from "config/language-keys";
import { renderMasterIndex } from "helpers/table";
import { AppUser, AppUserFilter } from "models/AppUser";
import { AppUserRoleMapping } from "models/AppUserRoleMapping";
import { Organization, OrganizationFilter } from "models/Organization";
/* end general import */
/* begin individual import */
import { Role, RoleFilter } from "models/Role";
import React, { useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  AdvanceStringFilter,
  AdvanceTreeFilter,
  LayoutCell,
  LayoutHeader,
  Modal,
  OneLineText,
  Pagination,
  StandardTable,
} from "react3l-ui-library";
import { roleRepository } from "repositories/role-repository";
// import { finalize, forkJoin } from "rxjs";
// import { webService } from "services/common-services/web-service";
import {
  FilterAction,
  filterReducer,
  filterService,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { v4 as uuidv4 } from "uuid";

import { IdFilter, NumberFilter, StringFilter } from "react3l-advanced-filters";
import { detailService } from "services/page-services/detail-service";
import { tableService } from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
/* end individual import */

export function useAppUserRoleMappingTable(model: Role, dispatch: any) {
  const [translate] = useTranslation();

  const [appUsers, setAppUsers] = React.useState<AppUser[]>([]);
  const [appUserFilter] = React.useReducer<
    React.Reducer<AppUserFilter, FilterAction<AppUserFilter>>
  >(filterReducer, new AppUserFilter());

  const { list } = listService.useLocalList(appUsers, appUserFilter);

  React.useEffect(() => {
    if (model.appUserRoleMappings && model.appUserRoleMappings.length > 0) {
      setAppUsers([
        ...model.appUserRoleMappings.map(
          (mapping: AppUserRoleMapping) => mapping.appUser
        ),
      ]);
    }
  }, [model.appUserRoleMappings]);

  const handleDeleteAppUser = React.useCallback(
    (user) => {
      ModalAnt.confirm({
        title: translate(generalLanguageKeys.deletes.content),
        content: translate(generalLanguageKeys.deletes.title),
        cancelText: translate(generalLanguageKeys.deletes.cancel),
        okType: "danger",
        onOk() {
          const listAppUser = appUsers.filter((i: AppUser) => i.id !== user.id);
          setAppUsers([...listAppUser]);
          const list =
            listAppUser && listAppUser?.length > 0
              ? listAppUser.map((item) => {
                  const maping = new AppUserRoleMapping();
                  maping.appUser = item;
                  maping.appUserId = item?.id;
                  return maping;
                })
              : [];
          model.appUserRoleMappings = [...list];
          dispatch(model);
        },
      });
    },
    [appUsers, dispatch, model, translate]
  );

  const appUserRoleMappingColumns: ColumnProps<AppUser>[] = React.useMemo(
    () => [
      {
        title: (
          <div className="text-center gradient-text">
            {translate("general.columns.index")}
          </div>
        ),
        key: "index",
        width: 80,
        align: "center",
        render: renderMasterIndex<AppUser>(),
      },
      {
        title: (
          <>
            <LayoutHeader
              orderType="left"
              title={translate("roles.appUsers.displayName")}
            />
          </>
        ),
        key: nameof(appUsers[0].displayName),
        dataIndex: nameof(appUsers[0].displayName),
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[1]?.displayName} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <>
            <LayoutHeader
              orderType="left"
              title={translate("roles.appUsers.username")}
            />
          </>
        ),
        key: nameof(appUsers[0].username),
        dataIndex: nameof(appUsers[0].username),
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[1]?.username} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <>
            <LayoutHeader
              orderType="left"
              title={translate("roles.appUsers.phone")}
            />
          </>
        ),
        key: nameof(appUsers[0].phone),
        dataIndex: nameof(appUsers[0].phone),
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[1]?.phone} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <>
            <LayoutHeader
              orderType="left"
              title={translate("roles.appUsers.email")}
            />
          </>
        ),
        key: nameof(appUsers[0].email),
        dataIndex: nameof(appUsers[0].email),
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[1]?.email} />
            </LayoutCell>
          );
        },
      },
      {
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(...params: [string, AppUserRoleMapping, number]) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <TrashCan16
                color="red"
                onClick={() => handleDeleteAppUser(params[1])}
              />
            </div>
          );
        },
      },
    ],
    [appUsers, handleDeleteAppUser, list, translate]
  );

  return {
    list,
    appUserRoleMappingColumns,
  };
}

export function useAppUserRoleMappingModalHook(
  model: Role,
  setModel: (role: Role) => void,
  handleLoadList?: (filter: RoleFilter) => void
) {
  const [appUserFilter, dispatchAppUserFilter] = React.useReducer<
    React.Reducer<AppUserFilter, FilterAction<AppUserFilter>>
  >(filterReducer, new AppUserFilter());

  const {
    value: filter,
    handleChangeAllFilter: handleChangeAppUserAllFilter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
    handleChangeInputFilter,
  } = filterService.useFilter(appUserFilter, dispatchAppUserFilter);

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAppUserAllFilter
  );

  // add siteId to filter appUser
  const tmpFilter = React.useMemo(() => {
    return {
      ...filter,
      siteId: {
        equal: model?.siteId,
      },
    };
  }, [filter, model?.siteId]);

  const mappingData = React.useCallback(
    (selectedList: AppUser[]) => {
      const list: any = selectedList.map((item: AppUser) => {
        return {
          roleId: model?.id,
          appUserId: item.id,
          appUser: item,
        };
      });
      return list;
    },
    [model]
  );

  const {
    open: visible,
    listMapping,
    countMapping,
    checkedKeys,
    spinning,
    handleOpenMapping,
    handleCloseMapping,
    handleSaveMapping: handleSaveMappingAppUser,
    handleCancelMapping,
    handleCheck,
    handleCheckAll,
  } = detailService.useMappingService(
    roleRepository.listAppUser,
    roleRepository.countAppUser,
    mappingData,
    tmpFilter,
    model.appUserRoleMappings,
    "appUserId"
  );

  const rowSelection = useMemo(
    () => ({
      onSelect(record: AppUser, selected: boolean) {
        handleCheck(record, selected);
      },
      onSelectAll(
        selected: boolean,
        selectedRows: AppUser[],
        changeRows: AppUser[]
      ) {
        handleCheckAll(selected, selectedRows, changeRows);
      },
      selectedRowKeys: checkedKeys,
    }),
    [checkedKeys, handleCheck, handleCheckAll]
  );

  const handleSaveMapping = React.useCallback(() => {
    const newModel = { ...model };
    newModel.appUserRoleMappings = handleSaveMappingAppUser();
    setModel({ ...newModel });
  }, [handleSaveMappingAppUser, model, setModel]);

  return {
    open: visible,
    listMapping,
    countMapping,
    appUserFilter,
    spinning,
    handleOpenMapping,
    handleCloseMapping,
    handleSaveMapping,
    handleCancelMapping,
    rowSelection,
    handleChangeAppUserAllFilter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
    handleTableChange,
    handlePagination,
    handleChangeInputFilter,
  };
}

interface AppUserRoleMappingModalProps {
  visible: boolean;
  handleClose: () => void;
  handleSave: (data: Role) => void;
  translate: TFunction;
  appUserFilter?: AppUserFilter;
  handleChangeInputFilter?: (config: {
    fieldName: string;
    fieldType: string;
    classFilter: new (partial?: any) => StringFilter | NumberFilter;
  }) => (value?: any) => void;
  handleChangeSelectFilter?: (config: {
    fieldName: string;
    fieldType: string;
    classFilter: new (partial?: any) => IdFilter;
  }) => (value?: any) => void;
  handleChangeInputSearch: (string: any) => void;
  listAppUser?: AppUser[];
  countAppUser?: number;
  loadList?: boolean;
  handleTableChange?: (value: any) => void;
  handlePagination?: (skip: number, take: number) => void;
  rowSelection?: any;
  handleChangeAllFilter?: (value?: any) => void;
  handleSelectFilter?: any;
}
export function AppUserRoleMappingModal(props: AppUserRoleMappingModalProps) {
  const {
    visible,
    handleSave,
    handleClose,
    translate,
    appUserFilter,
    handleChangeInputFilter,
    listAppUser,
    countAppUser,
    loadList,
    handleTableChange,
    handlePagination,
    rowSelection,
    // handleChangeSelectFilter,
    handleSelectFilter,
    // handleChangeInputSearch,
    // handleChangeAllFilter,
  } = props;

  const columns: ColumnProps<AppUser>[] = useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("roles.appUsers.username")}
          />
        ),
        key: "username",
        dataIndex: "username",
        ellipsis: true,
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("roles.appUsers.displayName")}
          />
        ),
        key: "displayName",
        dataIndex: "displayName",
        ellipsis: true,
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("roles.appUsers.organization")}
          />
        ),
        key: "organization",
        dataIndex: "organization",
        ellipsis: true,
        render(...params: [Organization, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]?.name} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("roles.appUsers.phone")}
          />
        ),
        key: "phone",
        dataIndex: "phone",
        ellipsis: true,
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("roles.appUsers.email")}
          />
        ),
        key: "email",
        dataIndex: "email",
        ellipsis: true,
        render(...params: [string, AppUser, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
    ],
    [translate]
  );
  return (
    <>
      <Modal
        size={1024}
        visibleFooter={true}
        visible={visible}
        title={translate("roles.appUsers.title")}
        handleSave={handleSave}
        handleCancel={handleClose}
        titleButtonCancel={translate("general.actions.close")}
        titleButtonApply={translate("general.actions.save")}
      >
        <div className="modal-content">
          <Row>
            <Col lg={8} className="m-b--xxs p-r--sm">
              <AdvanceStringFilter
                value={appUserFilter["username"]["contain"]}
                onChange={handleChangeInputFilter({
                  fieldName: "username",
                  fieldType: "contain",
                  classFilter: StringFilter,
                })}
                isMaterial={true}
                className={"tio-search"}
                label={translate("roles.appUsers.username")}
                placeHolder={translate("roles.appUsers.placeholder.username")}
                type={0}
              />
            </Col>
            <Col lg={8} className="m-b--xxs p-r--sm">
              <AdvanceStringFilter
                value={appUserFilter["displayName"]["contain"]}
                onChange={handleChangeInputFilter({
                  fieldName: "displayName",
                  fieldType: "contain",
                  classFilter: StringFilter,
                })}
                isMaterial={true}
                className={"tio-search"}
                label={translate("roles.appUsers.displayName")}
                placeHolder={translate(
                  "roles.appUsers.placeholder.displayName"
                )}
                type={0}
              />
            </Col>
            <Col lg={8} className="m-b--xxs p-r--sm">
              {/* <AdvanceIdFilter
                value={appUserFilter["organizationValue"]}
                onChange={handleSelectFilter({
                  fieldName: "organization",
                  fieldType: "equal",
                  classFilter: IdFilter,
                })}
                classFilter={OrganizationFilter}
                getList={roleRepository.filterListOrganization}
                placeHolder={translate(
                  "roles.appUsers.placeHolder.organization"
                )}
                label={translate("roles.appUsers.organization")}
                type={0}
              /> */}
              <AdvanceTreeFilter
                placeHolder={translate(
                  "roles.appUsers.placeholder.organization"
                )}
                classFilter={OrganizationFilter}
                onChange={handleSelectFilter({
                  fieldName: "organization",
                  fieldType: "equal",
                  classFilter: IdFilter,
                })}
                checkStrictly={true}
                item={appUserFilter["organizationValue"]}
                getTreeData={roleRepository.filterListOrganization}
                label={translate("roles.appUsers.organization")}
                type={0}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={8} className="m-b--xxs p-r--sm">
              <AdvanceStringFilter
                value={appUserFilter["phone"]["contain"]}
                onChange={handleChangeInputFilter({
                  fieldName: "phone",
                  fieldType: "contain",
                  classFilter: StringFilter,
                })}
                isMaterial={true}
                className={"tio-search"}
                label={translate("roles.appUsers.phone")}
                placeHolder={translate("roles.appUsers.placeholder.phone")}
                type={0}
              />
            </Col>
            <Col lg={8} className="m-b--xxs p-r--sm">
              <AdvanceStringFilter
                value={appUserFilter["email"]["contain"]}
                onChange={handleChangeInputFilter({
                  fieldName: "email",
                  fieldType: "contain",
                  classFilter: StringFilter,
                })}
                isMaterial={true}
                className={"tio-search"}
                label={translate("roles.appUsers.email")}
                placeHolder={translate("roles.appUsers.placeholder.email")}
                type={0}
              />
            </Col>
          </Row>
          <div className=" m-t--sm">
            <StandardTable
              key={uuidv4()}
              rowKey={nameof(listAppUser[0].id)}
              columns={columns}
              dataSource={listAppUser}
              isDragable={true}
              tableSize={"md"}
              onChange={handleTableChange}
              loading={loadList}
              rowSelection={rowSelection}
            />
            <Pagination
              skip={appUserFilter?.skip}
              take={appUserFilter?.take}
              total={countAppUser}
              onChange={handlePagination}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
