/* begin general import */
import { Col, Row } from "antd";
import { ASSETS_SVG } from "config/consts";
import { AppUserGrouping, AppUserGroupingFilter } from "models/AppUserGrouping";
import { Status, StatusFilter } from "models/Status";
import React from "react";
import { useTranslation } from "react-i18next";
import { Model } from "react3l-common";
import { Select, TreeSelect } from "react3l-ui-library";
import Drawer, {
  DrawerProps,
} from "react3l-ui-library/build/components/Drawer/Drawer";
import FormItem from "react3l-ui-library/build/components/FormItem";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import { appUserGroupingRepository } from "repositories/app-user-grouping-repository";
import nameof from "ts-nameof.macro";
import { utilService } from "services/common-services/util-service";
import { filterService } from "services/page-services/filter-service";
import { Organization, OrganizationFilter } from "models/Organization";

interface AppUserGroupingDetailDrawerProps extends DrawerProps {
  model: AppUserGrouping;
  handleChangeSingleField?: (config: {
    fieldName: string;
  }) => (value: any) => void;
  handleChangeTreeField?: (config: {
    fieldName: string;
  }) => (values: any[], isMultiple: boolean) => void;
  handleChangeAllField?: (data: any) => void;
  loading?: boolean;
  currentNode?: AppUserGrouping;
  handleChangeSelectField: (config: {
    fieldName: string;
  }) => (idValue: number, value: Model) => void;
  currentOrganization?: Organization;
}

function AppUserGroupingDetailDrawer(props: AppUserGroupingDetailDrawerProps) {
  const [translate] = useTranslation();

  const {
    model,
    handleChangeTreeField,
    handleChangeSingleField,
    handleChangeAllField,
    handleChangeSelectField,
    loading,
    visible,
    handleSave,
    handleCancel,
    currentOrganization,
  } = props;

  const handleChangeParentField = React.useCallback(
    (values?: AppUserGrouping[], _tt?: boolean) => {
      const newParrent = values[0];
      const newModel: AppUserGrouping = { ...model };
      if (newParrent) {
        newModel.parent = newParrent;
        newModel.parentId = newParrent?.id;
        newModel.organization = newParrent.organization;
        newModel.organizationId = newParrent.organizationId;
      } else {
        newModel.parent = undefined;
        newModel.parentId = undefined;
      }
      handleChangeAllField({ ...newModel });
    },
    [handleChangeAllField, model]
  );

  const [statusList] = filterService.useEnumList<Status>(
    appUserGroupingRepository.singleListStatus
  );

  React.useEffect(() => {
    if (model?.statusId === undefined && statusList?.length > 0) {
      const newModel: AppUserGrouping = { ...model };
      newModel.statusId = statusList[1]?.id;
      newModel.status = statusList[1];
      if (currentOrganization?.id) {
        newModel.organization = currentOrganization;
        newModel.organizationId = currentOrganization?.id;
      }
      handleChangeAllField({ ...newModel });
    }
  }, [
    currentOrganization,
    handleChangeAllField,
    handleChangeSelectField,
    model,
    statusList,
  ]);

  const handleChangeStatus = React.useCallback(
    (statusId: number, status: Status) => {
      if (statusId !== null) {
        handleChangeSelectField({ fieldName: "status" })(statusId, status);
      } else {
        handleChangeSelectField({ fieldName: "status" })(1, undefined);
      }
    },

    [handleChangeSelectField]
  );
  return (
    <Drawer
      {...props}
      size="sm"
      visible={visible}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleClose={handleCancel}
      visibleFooter={true}
      title={
        model?.id
          ? translate("general.detail.title")
          : translate("general.actions.create")
      }
      titleButtonCancel={translate("general.actions.close")}
      titleButtonApply={translate("general.actions.save")}
    >
      {loading ? (
        <div className="loading-block">
          <img src={ASSETS_SVG + "/spinner.svg"} alt="Loading..." />
        </div>
      ) : (
        <div className="page page__detail">
          <div className="w-100 page__detail-tabs">
            <Row className="d-flex">
              <Col lg={24}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col lg={24} className="m-b--sm m-t--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.code)
                      )}
                      message={model.errors?.code}
                    >
                      <InputText
                        label={translate("appUserGroupings.code")}
                        type={0}
                        isRequired
                        value={model.code}
                        placeHolder={translate(
                          "appUserGroupings.placeholder.code"
                        )}
                        className={"tio-account_square_outlined"}
                        onChange={handleChangeSingleField({
                          fieldName: nameof(model.code),
                        })}
                      />
                    </FormItem>
                  </Col>

                  <Col lg={24} className="m-b--sm m-t--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.name)
                      )}
                      message={model.errors?.name}
                    >
                      <InputText
                        isRequired
                        label={translate("appUserGroupings.name")}
                        type={0}
                        value={model.name}
                        placeHolder={translate(
                          "appUserGroupings.placeholder.name"
                        )}
                        className={"tio-account_square_outlined"}
                        onChange={handleChangeSingleField({
                          fieldName: nameof(model.name),
                        })}
                      />
                    </FormItem>
                  </Col>

                  <Col lg={24} className="m-b--sm m-t--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.parent)
                      )}
                      message={model.errors?.parent}
                    >
                      <TreeSelect
                        label={translate("appUserGroupings.parent")}
                        type={0}
                        placeHolder={translate(
                          "appUserGroupings.placeholder.parent"
                        )}
                        selectable={true}
                        classFilter={AppUserGroupingFilter}
                        onChange={handleChangeParentField}
                        checkStrictly={true}
                        valueFilter={
                          model?.id
                            ? {
                                ...new AppUserGroupingFilter(),
                                id: {
                                  notEqual: model.id,
                                },
                              }
                            : new AppUserGroupingFilter()
                        }
                        getTreeData={
                          appUserGroupingRepository.singleListAppUserGrouping
                        }
                        item={model.parent}
                      />
                    </FormItem>
                  </Col>

                  <Col lg={24} className="m-b--sm m-t--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.status)
                      )}
                      message={model.errors?.status}
                    >
                      <Select
                        label={translate("appUserGroupings.status")}
                        type={0}
                        classFilter={StatusFilter}
                        searchProperty={"name"}
                        placeHolder={translate(
                          "appUserGroupings.placeholder.status"
                        )}
                        getList={appUserGroupingRepository.singleListStatus}
                        onChange={handleChangeStatus}
                        value={model.status}
                      />
                    </FormItem>
                  </Col>

                  <Col lg={24} className="m-b--sm m-t--sm">
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        model,
                        nameof(model.organization)
                      )}
                      message={model.errors?.organization}
                    >
                      <TreeSelect
                        isRequired
                        label={translate("appUserGroupings.organization")}
                        type={0}
                        placeHolder={translate(
                          "appUserGroupings.placeholder.organization"
                        )}
                        selectable={true}
                        classFilter={OrganizationFilter}
                        onChange={handleChangeTreeField({
                          fieldName: nameof(model.organization),
                        })}
                        checkStrictly={true}
                        getTreeData={
                          appUserGroupingRepository.singleListOrganization
                        }
                        disabled={model?.parentId ? true : false}
                        item={model.organization}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default AppUserGroupingDetailDrawer;
