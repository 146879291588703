import React, { useEffect } from "react";
import * as dhx from "../codebase/diagram";
import "../codebase/diagram.css";
import "./DiagramView.scss";
import {
  workflowStepDefault,
  workflowStepTemplate,
} from "../helper/convert-data";

function DiagramView(props: any) {
  const { data, scale } = props;

  useEffect(() => {
    // loading data into the diagram
    if (data && data.length > 0) {
      const diagram = new dhx.Diagram("diagram", {
        scale: scale,
      });

      diagram.addShape("workflowStep", {
        template: workflowStepTemplate,
        defaults: workflowStepDefault,
      });

      diagram.data.parse(data);
    }
  }, [data, scale]);

  return (
    <React.Fragment>
      <div id="diagram" className="dhx__diagram__view"></div>
    </React.Fragment>
  );
}

export default DiagramView;
