/* begin general import */
import Close16 from "@carbon/icons-react/es/close/16";
import Send16 from "@carbon/icons-react/es/send/16";
import { Col, Row, Switch } from "antd";
import PageHeader from "components/PageHeader/PageHeader";
import { LOGIN_CONFIGURATION_ROUTE } from "config/route-consts";
import { AppUser } from "models/AppUser";
import { LoginConfiguration } from "models/LoginConfiguration";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, StandardTable } from "react3l-ui-library";
import FormItem from "react3l-ui-library/build/components/FormItem";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import { loginConfigurationRepository } from "repositories/login-configuration-repository";
import { utilService } from "services/common-services/util-service";
import { detailService } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import nameof from "ts-nameof.macro";
import { useLoginConfigurationContent } from "./LoginConfigurationContentHook";
// import { useLoginConfigurationContent } from "./LoginConfigurationContentHook";
import "./LoginConfigurationDetail.scss";

function LoginConfigurationDetail() {
  const [translate] = useTranslation();

  const { model, dispatch } =
    detailService.useModel<LoginConfiguration>(LoginConfiguration);

  const { isDetail } = detailService.useGetIsDetail<LoginConfiguration>(
    loginConfigurationRepository.get,
    dispatch
  );

  const { columns, list } = useLoginConfigurationContent(model, dispatch);

  const {
    handleChangeAllField,
    handleChangeSingleField,
    // handleChangeSelectField,
  } = fieldService.useField(model, dispatch);
  const ref = React.useRef<boolean>(true);
  React.useEffect(() => {
    if (ref.current && !model?.id) {
      handleChangeAllField({ ...model, statusId: 1 });
      ref.current = false;
    }
  }, [model, handleChangeAllField]);

  const { handleSaveModel, handleGoMaster } =
    detailService.useActionsDetail<AppUser>(
      model,
      loginConfigurationRepository.save,
      handleChangeAllField,
      LOGIN_CONFIGURATION_ROUTE
    );
  const handleChangeStatus = React.useCallback(
    (checked) => {
      const newModel = { ...model };
      if (checked) {
        newModel.statusId = 1;
      } else {
        newModel.statusId = 0;
      }
      handleChangeAllField(newModel);
    },
    [handleChangeAllField, model]
  );
  return (
    <>
      <div className="page-content">
        <PageHeader
          title={translate("general.detail.title")}
          breadcrumbItems={[
            translate("loginConfigurations.master.header"),
            translate("loginConfigurations.master.subHeader"),
          ]}
        />
        <div className={`page page-detail p-t--lg p-l--md p-r--sm p-b--lg `}>
          <div className="page-detail__title p-b--sm">
            {!isDetail
              ? translate("general.actions.create")
              : translate("general.detail.title")}
          </div>
          <Row className="m-t--xxs m-l--xxxs">
            <Col span={6} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.code)
                )}
                message={model.errors?.code}
              >
                <InputText
                  label={translate("loginConfigurations.code")}
                  type={0}
                  isRequired
                  value={model.code}
                  placeHolder={translate(
                    "loginConfigurations.placeholder.code"
                  )}
                  className={"tio-account_square_outlined"}
                  onChange={handleChangeSingleField({
                    fieldName: nameof(model.code),
                  })}
                />
              </FormItem>
            </Col>
            <Col span={6} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.name)
                )}
                message={model.errors?.name}
              >
                <InputText
                  label={translate("loginConfigurations.name")}
                  type={0}
                  isRequired
                  value={model.name}
                  placeHolder={translate(
                    "loginConfigurations.placeholder.name"
                  )}
                  className={"tio-account_square_outlined"}
                  onChange={handleChangeSingleField({
                    fieldName: nameof(model.name),
                  })}
                />
              </FormItem>
            </Col>

            <Col span={6} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.status)
                )}
                message={model.errors?.status}
              >
                <div>
                  <div className="label-status">
                    {translate("loginConfigurations.status")}
                  </div>
                  <Switch
                    checked={model.statusId === 1 ? true : false}
                    onChange={handleChangeStatus}
                    className="switch_status"
                  />
                </div>
              </FormItem>
            </Col>
          </Row>
          <div className="page-detail__title m-b--xxl m-t--xl">
            Cấu hình chi tiết
          </div>
          <Row>
            <div className="table-content">
              <StandardTable
                rowKey={nameof(list[0].key)}
                columns={columns}
                dataSource={list}
                tableSize={"lg"}
              />
            </div>
          </Row>
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>
              <Button
                type="primary"
                className="btn--lg"
                icon={<Send16 />}
                onClick={handleSaveModel}
              >
                {translate("general.actions.save")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default LoginConfigurationDetail;
