import PageHeader from "components/PageHeader/PageHeader";
import Add16 from "@carbon/icons-react/es/add/16";
import {
  ActionBarComponent,
  Button,
  InputText,
  Modal,
  StandardTable,
} from "react3l-ui-library";
import Close16 from "@carbon/icons-react/es/close/16";
import Send16 from "@carbon/icons-react/es/send/16";
import { Col, Row } from "antd";
import { utilService } from "services/common-services/util-service";
import nameof from "ts-nameof.macro";
import Download16 from "@carbon/icons-react/es/download/16";
import { useMenuReportDetailHook } from "./MenuReportDetailHook";
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";

function MenuReportDetail() {
  const {
    // table contents
    translate,
    handleSaveModel,
    handleGoMaster,
    handleAddRow,
    variablesFileDownloadPath,
    listFileTemplate,
    isDetail,
    fileTemplateColumns,
    model,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    canBulkAction,
    handleBulkDelete,
    // modal contents
    loadingType,
    handleSaveModal,
    handleCloseModal,
    handleAddFileTemplateInput,
    setSelectedRowKeysFileTemplateInput,
    handleBulkDeleteFileTemplateInput,
    selectedRowKeysFileTemplateInput,
    rowSelectionFileTemplateInput,
    canBulkActionFileTemplateInput,
    fileTemplateInputColumns,
    fileTemplateInputsInternal,
    visible,
  } = useMenuReportDetailHook();

  return (
    <>
      <div className="page-content">
        <PageHeader
          title={translate("fileTemplates.master.subHeader")}
          breadcrumbItems={[
            translate("fileTemplates.master.header"),
            translate("fileTemplates.master.subHeader"),
          ]}
        />
        <div className="page page-detail p-t--lg p-l--md p-r--sm p-b--lg">
          <div className="page-detail__title p-b--sm">
            {!isDetail
              ? translate("general.actions.create")
              : translate("general.detail.title")}
          </div>
          <Row>
            <Col lg={6} className="m-b--xxs p-r--sm">
              <InputText
                label={translate("menuReports.site")}
                isRequired={true}
                type={0}
                value={model.site?.name}
                placeHolder={translate("menuReports.placeholder.site")}
                disabled={true}
              />
            </Col>
            <Col lg={6} className="m-b--xxs p-r--sm">
              <InputText
                label={translate("menuReports.menu")}
                isRequired={true}
                type={0}
                value={model.menu?.name}
                placeHolder={translate("menuReports.placeholder.menu")}
                disabled={true}
              />
            </Col>
            <Col lg={6} className="m-b--xxs p-r--sm">
              <InputText
                label={translate("menuReports.name")}
                isRequired={true}
                type={0}
                value={model.name}
                placeHolder={translate("menuReports.placeholder.name")}
                disabled={true}
              />
            </Col>
            <Col lg={6} className="m-b--xxs p-r--sm p-t--sm">
              <Button
                type="outline-primary"
                className="btn--lg"
                icon={<Download16 />}
                onClick={() => {
                  utilService.downloadURI(
                    variablesFileDownloadPath,
                    "fileName"
                  );
                }}
              >
                {translate("fileTemplates.exportVariables")}
              </Button>
            </Col>
          </Row>
          <Row className="p-t--xxs">
            <Col lg={24}>
              <div className="d-flex justify-content-end">
                <Button
                  type="secondary"
                  className="btn--lg"
                  icon={<Add16 />}
                  onClick={handleAddRow}
                >
                  {translate("general.actions.create")}
                </Button>
              </div>
              <div className="condition-table">
                <ActionBarComponent
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                >
                  <Button
                    icon={<TrashCan16 />}
                    type="ghost-primary"
                    className="btn--lg"
                    disabled={!canBulkAction}
                    onClick={handleBulkDelete}
                  >
                    {translate("general.actions.delete")}
                  </Button>
                </ActionBarComponent>
                <StandardTable
                  rowKey={nameof("key")}
                  rowSelection={rowSelection}
                  columns={fileTemplateColumns}
                  dataSource={listFileTemplate}
                  tableSize={"lg"}
                  scroll={{ y: 400 }}
                />
              </div>
            </Col>
          </Row>
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>
              <Button
                type="primary"
                className="btn--lg"
                icon={<Send16 />}
                onClick={handleSaveModel}
              >
                {translate("general.actions.save")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
      <Modal
        size={900}
        visibleFooter={true}
        visible={visible}
        title={translate("fileTemplates.fileTemplateInputs.title")}
        handleSave={handleSaveModal}
        handleCancel={handleCloseModal}
        titleButtonCancel={translate("general.actions.close")}
        titleButtonApply={translate("general.actions.save")}
        loadingType={loadingType}
      >
        <div className="modal-content">
          <div className="m-t--sm">
            <div className="d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg"
                icon={<Add16 />}
                onClick={handleAddFileTemplateInput}
              >
                {translate("general.actions.create")}
              </Button>
            </div>
            <div className="w-100">
              <ActionBarComponent
                selectedRowKeys={selectedRowKeysFileTemplateInput}
                setSelectedRowKeys={setSelectedRowKeysFileTemplateInput}
              >
                <Button
                  icon={<TrashCan16 />}
                  type="ghost-primary"
                  className="btn--lg"
                  disabled={!canBulkActionFileTemplateInput}
                  onClick={handleBulkDeleteFileTemplateInput}
                >
                  {translate("general.actions.delete")}
                </Button>
              </ActionBarComponent>
              <StandardTable
                rowKey={nameof("key")}
                rowSelection={rowSelectionFileTemplateInput}
                columns={fileTemplateInputColumns}
                dataSource={fileTemplateInputsInternal}
                tableSize={"lg"}
                scroll={{ y: 500 }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default MenuReportDetail;
