import { Popconfirm } from "antd";
import { ColumnProps } from "antd/lib/table";
import { renderMasterIndex } from "helpers/table";
import { TFunction } from "i18next";
import { FileTemplate } from "models/FileTemplate";
import { FileTemplateInput } from "models/FileTemplateInput";
import React from "react";
import {
  FormItem,
  InputNumber,
  InputText,
  LayoutCell,
  LayoutHeader,
} from "react3l-ui-library";
import nameof from "ts-nameof.macro";
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { DECIMAL } from "react3l-ui-library/build/components/Input/InputNumber";
import { v4 as uuidv4 } from "uuid";
import { menuReportRepository } from "repositories/menu-report-repository";
import { utilService } from "services/common-services/util-service";
import { RowSelectionType } from "antd/lib/table/interface";
type KeyType = string | number;
type Loading = "default" | "submitting" | "submitted" | "error";

enum FileTemplateInputEnum {
  UPDATE_ALL,
  UPDATE_FILE_TEMPLATE_INPUTS,
  UPDATE_VISIBLE,
  UPDATE_CURRENT_FILE_TEMPLATE,
}

interface FileTemplateInputState {
  fileTemplateInputs?: FileTemplateInput[];
  visible?: boolean;
  fileTemplate?: FileTemplate;
}

interface FileTemplateInputAction {
  type: FileTemplateInputEnum;
  data: FileTemplateInputState;
}

function fileTemplateInputReducer(
  state: FileTemplateInputState,
  action: FileTemplateInputAction
): FileTemplateInputState {
  switch (action.type) {
    case FileTemplateInputEnum.UPDATE_ALL:
      return {
        ...state,
        fileTemplateInputs: [...action.data.fileTemplateInputs],
        visible: action.data.visible,
        fileTemplate: { ...action.data.fileTemplate },
      };
    case FileTemplateInputEnum.UPDATE_FILE_TEMPLATE_INPUTS:
      return {
        ...state,
        fileTemplateInputs: [...action.data.fileTemplateInputs],
      };
    case FileTemplateInputEnum.UPDATE_VISIBLE:
      return {
        ...state,
        visible: action.data.visible,
      };
  }
}

export function useFileTemplateInputModalHook(
  updateFileTempalateInputs: (
    fileTemplateInputs: FileTemplateInput[],
    fileTemplate: FileTemplate
  ) => void,
  translate: TFunction
) {
  const [
    { fileTemplateInputs: fileTemplateInputsInternal, visible, fileTemplate },
    dispatchValue,
  ] = React.useReducer(fileTemplateInputReducer, {
    fileTemplateInputs: [],
    visible: false,
  });
  const [loadingType, setLoadingType] = React.useState<Loading>("default");

  const handleAddFileTemplateInput = React.useCallback(() => {
    dispatchValue({
      type: FileTemplateInputEnum.UPDATE_FILE_TEMPLATE_INPUTS,
      data: {
        fileTemplateInputs: [
          { ...new FileTemplateInput(), key: uuidv4() },
          ...fileTemplateInputsInternal,
        ],
      },
    });
  }, [fileTemplateInputsInternal]);

  const handleChangeFileTemplateInputCell = React.useCallback(
    (index: number, fieldKey: string) => (value: string | number) => {
      const fileTemplateInputValues = [...fileTemplateInputsInternal];
      const currentFileTemplateInput = fileTemplateInputValues[index];
      currentFileTemplateInput[fieldKey] = value;
      dispatchValue({
        type: FileTemplateInputEnum.UPDATE_FILE_TEMPLATE_INPUTS,
        data: {
          fileTemplateInputs: fileTemplateInputValues,
        },
      });
    },
    [fileTemplateInputsInternal]
  );

  const handleDeleteRow = React.useCallback(
    (index: number) => () => {
      if (index !== null) {
        fileTemplateInputsInternal.splice(index, 1);
        dispatchValue({
          type: FileTemplateInputEnum.UPDATE_FILE_TEMPLATE_INPUTS,
          data: {
            fileTemplateInputs: fileTemplateInputsInternal,
          },
        });
      }
    },
    [fileTemplateInputsInternal]
  );

  const handleOpenModal = React.useCallback((fileTemplate: FileTemplate) => {
    dispatchValue({
      type: FileTemplateInputEnum.UPDATE_ALL,
      data: {
        fileTemplateInputs:
          fileTemplate && fileTemplate.fileTemplateInputs
            ? fileTemplate.fileTemplateInputs
            : [],
        visible: true,
        fileTemplate: fileTemplate,
      },
    });
  }, []);

  const handleCloseModal = React.useCallback(() => {
    dispatchValue({
      type: FileTemplateInputEnum.UPDATE_VISIBLE,
      data: {
        visible: false,
      },
    });
  }, []);

  const handleSaveModal = React.useCallback(() => {
    setLoadingType("submitting");
    menuReportRepository
      .validateInputs({
        ...new FileTemplate(),
        fileTemplateInputs: [...fileTemplateInputsInternal],
      })
      .subscribe({
        next: (res: FileTemplate) => {
          setLoadingType("default");
          if (res) {
            dispatchValue({
              type: FileTemplateInputEnum.UPDATE_VISIBLE,
              data: {
                visible: false,
              },
            });
            updateFileTempalateInputs(fileTemplateInputsInternal, fileTemplate);
          }
        },
        error: () => {
          setLoadingType("error");
        },
      });
  }, [fileTemplate, fileTemplateInputsInternal, updateFileTempalateInputs]);

  const [
    selectedRowKeysFileTemplateInput,
    setSelectedRowKeysFileTemplateInput,
  ] = React.useState<KeyType[]>([]);

  const rowSelectionFileTemplateInput = React.useMemo(
    () => ({
      onChange(selectedRowKeys: KeyType[]) {
        setSelectedRowKeysFileTemplateInput(selectedRowKeys);
      },
      selectedRowKeysFileTemplateInput,
      type: "checkbox" as RowSelectionType,
      getCheckboxProps: (record: FileTemplate) => ({
        disabled: record.used,
      }),
    }),
    [selectedRowKeysFileTemplateInput]
  );

  const canBulkActionFileTemplateInput = React.useMemo(
    () => selectedRowKeysFileTemplateInput.length > 0,
    [selectedRowKeysFileTemplateInput.length]
  );

  const handleBulkDeleteFileTemplateInput = React.useCallback(() => {
    const selectedRowKeysValue = [...selectedRowKeysFileTemplateInput];
    const listFileTemplateInputValue = [...fileTemplateInputsInternal].filter(
      (currentFile: FileTemplate) =>
        !selectedRowKeysValue.includes(currentFile.key)
    );
    dispatchValue({
      type: FileTemplateInputEnum.UPDATE_FILE_TEMPLATE_INPUTS,
      data: {
        fileTemplateInputs: [...listFileTemplateInputValue],
      },
    });
    setSelectedRowKeysFileTemplateInput([]);
  }, [fileTemplateInputsInternal, selectedRowKeysFileTemplateInput]);

  const columns: ColumnProps<FileTemplateInput>[] = React.useMemo(() => {
    return [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
          />
        ),
        key: "index",
        width: 80,
        align: "center",
        render: renderMasterIndex<FileTemplateInput>(),
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplateInputs.fieldName")}
          />
        ),
        key: nameof("fieldName"),
        dataIndex: nameof("fieldName"),
        render(...params: [string, FileTemplateInput, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  "fieldName"
                )}
                message={utilService.getValidateMessage(params[1], "fieldName")}
              >
                <InputText
                  type={0}
                  value={params[0]}
                  placeHolder={translate(
                    "fileTemplateInputs.placeholder.fieldName"
                  )}
                  onChange={handleChangeFileTemplateInputCell(
                    params[2],
                    "fieldName"
                  )}
                />
              </FormItem>
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplateInputs.fieldValue")}
          />
        ),
        key: nameof("fieldValue"),
        dataIndex: nameof("fieldValue"),
        render(...params: [string, FileTemplateInput, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  "fieldValue"
                )}
                message={utilService.getValidateMessage(
                  params[1],
                  "fieldValue"
                )}
              >
                {" "}
                <InputText
                  type={0}
                  value={params[0]}
                  placeHolder={translate(
                    "fileTemplateInputs.placeholder.fieldValue"
                  )}
                  onChange={handleChangeFileTemplateInputCell(
                    params[2],
                    "fieldValue"
                  )}
                />
              </FormItem>
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplateInputs.order")}
          />
        ),
        width: 200,
        key: nameof("order"),
        dataIndex: nameof("order"),
        render(...params: [number, FileTemplateInput, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  "order"
                )}
                message={utilService.getValidateMessage(params[1], "order")}
              >
                {" "}
                <InputNumber
                  value={params[0]}
                  onChange={handleChangeFileTemplateInputCell(
                    params[2],
                    "order"
                  )}
                  numberType={DECIMAL}
                  placeHolder={translate(
                    "fileTemplateInputs.placeholder.order"
                  )}
                  type={0}
                />
              </FormItem>
            </LayoutCell>
          );
        },
      },
      {
        key: nameof("action"),
        dataIndex: nameof("action"),
        fixed: "right",
        width: 120,
        align: "center",
        render(...params: [string, FileTemplateInput, number]) {
          return (
            <Popconfirm
              title={translate("general.warnings.delete")}
              onConfirm={handleDeleteRow(params[2])}
              okText={translate("general.actions.accept")}
              cancelText={translate("general.actions.deny")}
            >
              <TrashCan16 color="red" />
            </Popconfirm>
          );
        },
      },
    ];
  }, [translate, handleChangeFileTemplateInputCell, handleDeleteRow]);

  return {
    loadingType,
    selectedRowKeysFileTemplateInput,
    rowSelectionFileTemplateInput,
    canBulkActionFileTemplateInput,
    setSelectedRowKeysFileTemplateInput,
    handleBulkDeleteFileTemplateInput,
    handleOpenModal,
    handleSaveModal,
    handleCloseModal,
    handleAddFileTemplateInput,
    handleChangeFileTemplateInputCell,
    fileTemplateInputColumns: columns,
    fileTemplateInputsInternal,
    visible,
  };
}
