import { LoginConfigurationContent } from "models/LoginConfigurationContent";
import { Status } from "models/Status";
import { Model } from "react3l-common";
import { Field, ObjectField } from "react3l-decorators";
export class LoginConfiguration extends Model {
  @Field(Number)
  public id?: number;

  @Field(String)
  public code?: string;

  @Field(String)
  public name?: string;

  @Field(Number)
  public statusId?: number;

  @ObjectField(Status)
  public status?: Status;

  public loginConfigurationContents?: LoginConfigurationContent[];
}
