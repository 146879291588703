/* begin general import */
import { Col, Row, Switch } from "antd";
import { ASSETS_SVG } from "config/consts";
import { Moment } from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Model } from "react3l-common";
import Drawer, {
  DrawerProps,
} from "react3l-ui-library/build/components/Drawer/Drawer";
import FormItem from "react3l-ui-library/build/components/FormItem";
import { utilService } from "services/common-services/util-service";
import { ModelAction } from "services/page-services/detail-service";
import nameof from "ts-nameof.macro";

/* end general import */

/* begin individual import */
import { Position } from "models/Position";
import { Status } from "models/Status";
import InputText from "react3l-ui-library/build/components/Input/InputText";
import { positionRepository } from "repositories/position-repository";
import { filterService } from "services/page-services/filter-service";
/* end individual import */

interface PositionDetailDrawerProps extends DrawerProps {
  model: Position;
  handleChangeSingleField?: (config: {
    fieldName: string;
  }) => (value: any) => void;
  handleChangeSelectField?: (config: {
    fieldName: string;
  }) => (idValue: number, value: Model) => void;
  handleChangeTreeField?: (config: {
    fieldName: string;
  }) => (values: any[], isMultiple: boolean) => void;
  handleChangeDateField?: (config: {
    fieldName: string | [string, string];
  }) => (date: Moment | [Moment, Moment]) => void;
  dispatch?: React.Dispatch<ModelAction<Position>>;
  loading?: boolean;
}

function PositionDetailDrawer(props: PositionDetailDrawerProps) {
  const [translate] = useTranslation();

  const {
    model,
    handleChangeSingleField,
    handleChangeSelectField,
    loading,
    visible,
    handleSave,
    handleCancel,
  } = props;
  const [statusList] = filterService.useEnumList<Status>(
    positionRepository.singleListStatus
  );

  const handleChangeStatus = React.useCallback(
    (checked) => {
      let status = new Status();
      if (checked) {
        status = statusList[1];
      } else {
        status = statusList[0];
      }
      handleChangeSelectField({ fieldName: "status" })(status.id, status);
    },
    [handleChangeSelectField, statusList]
  );
  return (
    <Drawer
      {...props}
      visible={visible}
      handleSave={handleSave}
      handleCancel={handleCancel}
      handleClose={handleCancel}
      visibleFooter={true}
      title={
        model?.id
          ? translate("general.detail.title")
          : translate("general.actions.create")
      }
      titleButtonCancel={translate("general.actions.close")}
      titleButtonApply={translate("general.actions.save")}
    >
      {loading ? (
        <div className="loading-block">
          <img src={ASSETS_SVG + "/spinner.svg"} alt="Loading..." />
        </div>
      ) : (
        <div className="page page__detail">
          <div className="w-100 page__detail-tabs">
            <Row className="d-flex" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col lg={24} className="m-b--xs m-t--xs">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.code)
                  )}
                  message={model.errors?.code}
                >
                  <InputText
                    label={translate("positions.code")}
                    type={0}
                    value={model.code}
                    placeHolder={translate("positions.placeholder.code")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.code),
                    })}
                    isRequired
                  />
                </FormItem>
              </Col>

              <Col lg={24} className="m-b--xs m-t--xs">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.name)
                  )}
                  message={model.errors?.name}
                >
                  <InputText
                    label={translate("positions.name")}
                    type={0}
                    value={model.name}
                    placeHolder={translate("positions.placeholder.name")}
                    className={"tio-account_square_outlined"}
                    onChange={handleChangeSingleField({
                      fieldName: nameof(model.name),
                    })}
                    isRequired
                  />
                </FormItem>
              </Col>

              <Col lg={24} className="m-b--xs m-t--xs">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    model,
                    nameof(model.status)
                  )}
                  message={model.errors?.status}
                >
                  <div>
                    <div className="label-status">
                      {translate("positions.status")}
                    </div>
                    <Switch
                      checked={model.statusId === 1 ? true : false}
                      onChange={handleChangeStatus}
                      className="switch_status"
                    />
                  </div>
                </FormItem>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default PositionDetailDrawer;
