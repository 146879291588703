import { Launch16 } from "@carbon/icons-react";
import { AppUser } from "models/AppUser";
import { AppUserSiteMapping } from "models/AppUserSiteMapping";
import React, { Dispatch, SetStateAction } from "react";
import { profileRepository } from "repositories/profile-repository";
import "./LandingPageDrawer.scss";

export interface LandingPageDrawerProps {
  isShowDrawer: boolean;
  setIsShowDrawer: Dispatch<SetStateAction<boolean>>;
  displayDrawer?: boolean;
  setDisplayDrawer?: Dispatch<SetStateAction<boolean>>;
}
function useOnClickOutside(ref: any, handler: () => void) {
  React.useEffect(() => {
    const listenerClickOutside = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler();
    };
    document.addEventListener("click", listenerClickOutside, true);
    return () => {
      document.removeEventListener("click", listenerClickOutside, true);
    };
  }, [ref, handler]);
}

function LandingPageDrawer(props: LandingPageDrawerProps) {
  const { isShowDrawer, setIsShowDrawer, displayDrawer, setDisplayDrawer } =
    props;
  const firstLoad = React.useRef<boolean>(true);
  const [user, setUser] = React.useState<AppUser>();
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (firstLoad.current) {
      profileRepository.get().subscribe((res) => setUser(res));
      firstLoad.current = false;
    }
  }, []);

  const handleClickLandingPage = React.useCallback(() => {
    window.location.href = `/landing-page/`;
  }, []);

  useOnClickOutside(ref, () => {
    if (isShowDrawer) {
      setTimeout(() => {
        setDisplayDrawer(false);
      }, 500);
      setIsShowDrawer(false);
    }
  });

  // check if list site include siteTypeId
  const handleCheckIncludeSiteType = React.useCallback(
    (siteTypeId: number) => {
      const listSiteTypeId: number[] = [];
      if (user?.appUserSiteMappings && user?.appUserSiteMappings?.length > 0) {
        user?.appUserSiteMappings.forEach(
          (siteItem: { site: any; enabled: boolean }) => {
            if (siteItem.enabled) {
              listSiteTypeId.push(siteItem?.site?.siteTypeId);
            }
          }
        );
        if (listSiteTypeId.includes(siteTypeId)) {
          return true;
        }
      }
      return false;
    },
    [user?.appUserSiteMappings]
  );

  const renderSite = React.useCallback(
    (siteTypeId?: number) => {
      return (
        <React.Fragment>
          {user?.appUserSiteMappings?.length > 0 &&
            user?.appUserSiteMappings
              ?.filter(
                (siteItem: { site: any }) =>
                  siteItem?.site?.siteTypeId === siteTypeId
              )
              .map(
                (item: any, i: any) =>
                  item?.enabled &&
                  item.siteId < 100 && (
                    <SiteLine key={i} item={item} index={i} />
                  )
              )}
        </React.Fragment>
      );
    },
    [user]
  );

  return (
    <div className={displayDrawer ? "display-drawer" : "hide-drawer"}>
      {" "}
      <div
        className={`landing-drawer__wrapper  ${isShowDrawer && "show"}`}
        ref={ref}
      >
        <div className="landing-drawer__container">
          <div className="landing-drawer__container-content">
            {handleCheckIncludeSiteType(1) && (
              <div className="landing-drawer__site-grouping m-b--xxl p-t--sm">
                <div className="landing-drawer__site-grouping-title p-y--sm m-t--xxs m-b--xxs">
                  Bán hàng
                </div>

                <div className="landing-drawer__list-site">{renderSite(1)}</div>
              </div>
            )}

            {handleCheckIncludeSiteType(2) && (
              <div className="landing-drawer__site-grouping m-b--xxl">
                <div className="landing-drawer__site-grouping-title p-y--sm m-t--xxs m-b--xxs">
                  Vận hành
                </div>

                <div className="landing-drawer__list-site">{renderSite(2)}</div>
              </div>
            )}

            {handleCheckIncludeSiteType(3) && (
              <div className="landing-drawer__site-grouping m-b--xxl">
                <div className="landing-drawer__site-grouping-title p-y--sm m-t--xxs m-b--xxs">
                  Cài đặt
                </div>

                <div className="landing-drawer__list-site">{renderSite(3)}</div>
              </div>
            )}
            {/** group các site thuộc nhóm cài đặt */}
          </div>

          <div
            className="landing-drawer__all-site p-t--sm"
            onClick={() => handleClickLandingPage()}
          >
            <div className="landing-drawer__all-site--label color-white m-r--xxxs">
              Tất cả
            </div>
            <div className="landing-drawer__all-site--icon">
              <Launch16 color={"#fff"} />
            </div>
          </div>
        </div>
      </div>
      {isShowDrawer && (
        <div
          className={`landing-page__overlay ${isShowDrawer && "show"}`}
        ></div>
      )}
    </div>
  );
}
export interface SiteCardProps {
  index: number;
  item: AppUserSiteMapping;
}
export function SiteLine(props: SiteCardProps) {
  const { item } = props;

  const handleClickSite = React.useCallback(() => {
    window.location.href = `${item?.site?.code}`;
  }, [item]);

  return (
    <div
      className="landing-drawer__site-item p-y--xxs"
      onClick={handleClickSite}
    >
      <div className="landing-drawer__site-item-icon">
        <img
          src={item?.site?.lightIcon}
          alt=""
          style={{ width: 16, height: 16 }}
        />
      </div>
      <div className="landing-drawer__site-item-title">{item?.site?.name}</div>
    </div>
  );
}
LandingPageDrawer.defaultProps = {
  isShowDrawer: false,
};
export default LandingPageDrawer;
