import { AppUserSiteMapping } from "models/AppUserSiteMapping";
import React from "react";
import { CSSTransition } from "react-transition-group";
// import { ButtonDropdown, DropdownToggle } from "reactstrap";
import { AppUser } from "models/AppUser";
import "./LandingPage.scss";
// import { MenuOutlined } from "@ant-design/icons";
import * as Cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { profileRepository } from "repositories/profile-repository";
import LandingPageDrawer from "./LandingPageDrawer/LandingPageDrawer";
import LandingPageHeader from "./LandingPageHeader";
export interface DecodeToken {
  exp: Number;
}
function LandingPage() {
  const firstLoad = React.useRef<boolean>(true);
  const [user, setUser] = React.useState<AppUser>();

  const token = Cookie.get("Token");
  if (
    !token ||
    (token && jwtDecode<DecodeToken>(token).exp < moment.now() / 1000)
  ) {
    window.location.href = "/login";
  }

  React.useEffect(() => {
    if (firstLoad.current) {
      profileRepository.get().subscribe((res) => setUser(res));
      firstLoad.current = false;
    }
  }, []);

  const [showD, setShowD] = React.useState<boolean>(false);

  const [loadingLandingPage, setLoadingLandingPage] =
    React.useState<boolean>(true);

  React.useEffect(() => {
    if (user) {
      setLoadingLandingPage(false);
    }
  }, [user]);

  const startTime = React.useCallback(() => {
    const today = new Date();
    const h = today.getHours();
    let m = today.getMinutes();
    // let s = today.getSeconds();
    m = checkTime(m);
    // s = checkTime(s);
    if (document.getElementById("clock")) {
      document.getElementById("clock").innerHTML = h + ":" + m;
    }

    setTimeout(startTime, 500);
  }, []);

  function checkTime(i: any) {
    if (i < 10) {
      i = "0" + i;
    } // add zero in front of numbers < 10
    return i;
  }

  // check if list site include siteTypeId
  const handleCheckIncludeSiteType = React.useCallback(
    (siteTypeId: number) => {
      const listSiteTypeId: number[] = [];
      if (user?.appUserSiteMappings && user?.appUserSiteMappings?.length > 0) {
        user?.appUserSiteMappings.forEach(
          (siteItem: { site: any; enabled: boolean }) => {
            if (siteItem.enabled) {
              listSiteTypeId.push(siteItem?.site?.siteTypeId);
            }
          }
        );
        if (listSiteTypeId.includes(siteTypeId)) {
          return true;
        }
      }
      return false;
    },
    [user?.appUserSiteMappings]
  );

  const items = React.useCallback(
    (siteTypeId?: number) => {
      return (
        <React.Fragment>
          {user?.appUserSiteMappings?.length > 0 &&
            user?.appUserSiteMappings
              ?.filter(
                (siteItem: { site: any }) =>
                  siteItem?.site?.siteTypeId === siteTypeId
              )
              .map(
                (item: any, i: any) =>
                  item?.enabled &&
                  item.siteId < 100 && (
                    <SiteCard key={i} item={item} index={i} />
                  )
              )}
        </React.Fragment>
      );
    },
    [user]
  );

  const [isShowDrawer, setIsShowDrawer] = React.useState<boolean>(false);

  const handleClickLandingDrawer = React.useCallback(() => {
    if (isShowDrawer) {
      setIsShowDrawer(false);
    } else {
      setShowD(true);
      setTimeout(() => {
        setIsShowDrawer(true);
      }, 1);
    }
  }, [isShowDrawer]);

  return (
    <>
      <CSSTransition
        in={!loadingLandingPage}
        timeout={100}
        classNames="landing-page"
        unmountOnExit
        onLoad={startTime}
      >
        <div className="landing-page">
          <LandingPageHeader
            isShowDrawer={isShowDrawer}
            handleClick={handleClickLandingDrawer}
          />
          <div className="landing-page__content p-l--sm p-r--xxl">
            <div className="landing-page__breadcrumb p-l--sm p-b--sm">
              <div className="landing-page__breadcrumb-item--small m-b--xxs">
                Home/
              </div>
              <div className="landing-page__breadcrumb-item--big">
                Tất cả ứng dụng
              </div>
            </div>

            <div className="list-site-box__wrapper">
              <div className="landing-page__list-site-box">
                {handleCheckIncludeSiteType(1) && (
                  <div
                    className={`landing-page__list-site-container p-t--lg p-b--xxl ${
                      handleCheckIncludeSiteType(2) ||
                      handleCheckIncludeSiteType(3)
                        ? "not-last"
                        : ""
                    } `}
                  >
                    <div className="landing-page__site-grouping-title m-b--sm p-l--sm">
                      Bán hàng
                    </div>

                    <div className="list-site-wrapper">{items(1)}</div>
                  </div>
                )}

                {handleCheckIncludeSiteType(2) && (
                  <>
                    <div
                      className={`landing-page__list-site-container p-t--lg p-b--xxl ${
                        handleCheckIncludeSiteType(3) ? "not-last" : ""
                      }`}
                    >
                      <div className="landing-page__site-grouping-title m-b--sm p-l--sm">
                        Vận hành
                      </div>

                      <div className="list-site-wrapper">{items(2)}</div>
                    </div>
                  </>
                )}
                {handleCheckIncludeSiteType(3) && (
                  <div className="landing-page__list-site-container p-t--lg p-b--xxl">
                    <div className="landing-page__site-grouping-title m-b--sm p-l--sm">
                      Cài đặt
                    </div>

                    <div className="list-site-wrapper">{items(3)}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <LandingPageDrawer
            isShowDrawer={isShowDrawer}
            setIsShowDrawer={setIsShowDrawer}
            displayDrawer={showD}
            setDisplayDrawer={setShowD}
          />
        </div>
      </CSSTransition>
      <CSSTransition
        in={loadingLandingPage}
        timeout={100}
        classNames="landing-page-mask"
        unmountOnExit
      >
        <div id="loader-wrapper">
          <div id="loader"></div>

          <div className="loader-section section-left"></div>
          <div className="loader-section section-right"></div>
        </div>
      </CSSTransition>
    </>
  );
}

export interface SiteCardProps {
  index: number;
  item: AppUserSiteMapping;
}

export function SiteCard(props: AppUserSiteMapping) {
  const { item } = props;

  const handleClickSite = React.useCallback(() => {
    window.location.href = `${item?.site?.code}`;
  }, [item]);

  return (
    <div
      className="site-card__container p--sm m-x--sm m-b--sm"
      onClick={handleClickSite}
    >
      <div className="site-card__icon m-b--xxs">
        <img src={item?.site?.darkIcon} alt="" />
      </div>
      <div className="site-card__title m-b--xxxs">{item?.site?.name}</div>
      <div className="site-card__description text-truncate">
        {item?.site?.description}
      </div>
    </div>
  );
}

export default LandingPage;
