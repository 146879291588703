import { Position } from './Position'
import nameof from 'ts-nameof.macro';
import { ObjectField,  } from 'react3l-decorators';

import { Status } from 'models/Status';

ObjectField(Status)(Position.prototype, nameof(Position.prototype.status));

export * from './Position';
export * from './PositionFilter';
