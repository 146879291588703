/* begin general import */

import Add16 from "@carbon/icons-react/es/add/16";
import Send16 from "@carbon/icons-react/es/send/16";
import Close16 from "@carbon/icons-react/es/close/16";
import { Col, Row, Switch, Tabs } from "antd";
import PageHeader from "components/PageHeader/PageHeader";
/* end general import */
/* begin individual import */
import { ROLE_DETAIL_ROUTE, ROLE_ROUTE } from "config/route-consts";
import { Role } from "models/Role";
import { SiteFilter } from "models/Site";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  DatePicker,
  FormItem,
  InputText,
  Select,
  StandardTable,
  TagFilter,
} from "react3l-ui-library";
import { roleRepository } from "repositories/role-repository";
import appMessageService from "services/common-services/app-message-service";
import { utilService } from "services/common-services/util-service";
import { detailService } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import nameof from "ts-nameof.macro";
import PermissionRoleDetail from "./PermissionRoleView/PermissionRoleDetail";
import "./RoleDetail.scss";
import AppUserRoleAdvanceFilter from "./RoleDetailHook/AppUserRoleAdvanceFilter";
import {
  AppUserRoleMappingModal,
  useAppUserRoleMappingModalHook,
  useAppUserRoleMappingTable,
} from "./RoleDetailHook/AppUserRoleMappingHook";
import PermissionGeneralField from "./GeneralField/GeneralFieldTable";
import PermissionGeneralActionType from "./GeneralActionType/GeneralActionTypeTable";
/* end individual import */
const { TabPane } = Tabs;
function RoleDetail() {
  const [translate] = useTranslation();
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  const url = document.URL;

  const isLinkAssginAppUser = React.useMemo(() => {
    let isCheck: boolean = false;
    if (url.includes("assign-app-user")) {
      isCheck = true;
    }
    return isCheck;
  }, [url]);

  const { model, dispatch } = detailService.useModel<Role>(Role);

  const { isDetail } = detailService.useGetIsDetail<Role>(
    roleRepository.get,
    dispatch
  );

  const {
    handleChangeAllField,
    handleChangeSingleField,
    handleChangeSelectField,
    handleChangeDateField,
  } = fieldService.useField(model, dispatch);

  const handleChangeStatus = React.useCallback(
    (checked) => {
      const newModel = { ...model };
      if (checked) {
        newModel.statusId = 1;
      } else {
        newModel.statusId = 0;
      }
      handleChangeAllField(newModel);
    },
    [handleChangeAllField, model]
  );

  const { notifyUpdateItemSuccess, notifyUpdateItemError } =
    appMessageService.useCRUDMessage();
  const handleSaveRole = React.useCallback(() => {
    roleRepository.save(model).subscribe(
      (res) => {
        notifyUpdateItemSuccess();
        handleChangeAllField(res);
        if (!isDetail) {
          window.location.href = `${ROLE_DETAIL_ROUTE}?id=${res.id}`;
        }
      },
      (error) => {
        if (error.response && error.response.status === 400)
          handleChangeAllField(error.response?.data);
        notifyUpdateItemError();
      }
    );
  }, [
    handleChangeAllField,
    isDetail,
    model,
    notifyUpdateItemError,
    notifyUpdateItemSuccess,
  ]);

  const { handleGoMaster } = detailService.useActionsDetail<Role>(
    model,
    roleRepository.save,
    handleChangeAllField,
    ROLE_ROUTE
  );

  const handleAssignAppUser = React.useCallback(
    (model) => {
      roleRepository.assignAppUser(model).subscribe(
        (item: Role) => {
          notifyUpdateItemSuccess();
          handleGoMaster();
        },
        (err) => {
          notifyUpdateItemError();
        }
      );
    },
    [handleGoMaster, notifyUpdateItemError, notifyUpdateItemSuccess]
  );
  const { list: appUsers, appUserRoleMappingColumns } =
    useAppUserRoleMappingTable(model, handleChangeAllField);

  const {
    open,
    listMapping,
    countMapping,
    appUserFilter,
    rowSelection,
    spinning,
    handleOpenMapping,
    handleSaveMapping,
    handleCancelMapping,
    handleChangeSelectFilter,
    handleChangeInputSearch,
    handleChangeAppUserAllFilter,
    handlePagination,
    handleChangeInputFilter,
  } = useAppUserRoleMappingModalHook(model, handleChangeAllField);

  return (
    <>
      <div className="page-content">
        <PageHeader
          title={translate("roles.master.subHeader")}
          breadcrumbItems={[
            translate("roles.master.header"),
            translate("roles.master.subHeader"),
          ]}
        />
        <div className="page page-detail p-t--lg p-l--xxl p-r--xxl p-b--lg">
          <div className="page-detail__title p-b--sm">
            {!isDetail
              ? translate("general.actions.create")
              : translate("general.detail.title")}
          </div>
          <Row>
            <Col lg={6} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.code)
                )}
                message={model.errors?.code}
              >
                <InputText
                  isRequired
                  label={translate("roles.code")}
                  type={0}
                  value={model.code}
                  placeHolder={translate("roles.placeholder.code")}
                  className={"tio-account_square_outlined"}
                  onChange={handleChangeSingleField({
                    fieldName: nameof(model.code),
                  })}
                  disabled={isLinkAssginAppUser ? true : false}
                />
              </FormItem>
            </Col>
            <Col lg={6} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.name)
                )}
                message={model.errors?.name}
              >
                <InputText
                  isRequired
                  label={translate("roles.name")}
                  type={0}
                  value={model.name}
                  placeHolder={translate("roles.placeholder.name")}
                  className={"tio-account_square_outlined"}
                  onChange={handleChangeSingleField({
                    fieldName: nameof(model.name),
                  })}
                  disabled={isLinkAssginAppUser ? true : false}
                />
              </FormItem>
            </Col>

            <Col lg={6} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.site)
                )}
                message={model.errors?.site}
              >
                <Select
                  isRequired
                  label={translate("roles.site")}
                  type={0}
                  classFilter={SiteFilter}
                  placeHolder={translate("roles.placeholder.site")}
                  getList={roleRepository.singleListSite}
                  onChange={handleChangeSelectField({
                    fieldName: nameof(model.site),
                  })}
                  value={model.site}
                  disabled={isDetail}
                />
              </FormItem>
            </Col>
            <Col lg={6} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.startAt)
                )}
                message={model.errors?.startAt}
              >
                <DatePicker
                  label={translate("roles.startAt")}
                  value={model.startAt}
                  type={0}
                  onChange={handleChangeDateField({
                    fieldName: nameof(model.startAt),
                  })}
                  placeholder={translate("roles.placeholder.startAt")}
                  disabled={isLinkAssginAppUser ? true : false}
                />
              </FormItem>
            </Col>
            <Col lg={6} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.endAt)
                )}
                message={model.errors?.endAt}
              >
                <DatePicker
                  label={translate("roles.endAt")}
                  value={model.endAt}
                  type={0}
                  onChange={handleChangeDateField({
                    fieldName: nameof(model.endAt),
                  })}
                  placeholder={translate("roles.placeholder.endAt")}
                  disabled={isLinkAssginAppUser ? true : false}
                />
              </FormItem>
            </Col>
            <Col lg={6} className="m-b--xxs p-r--sm">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  model,
                  nameof(model.status)
                )}
                message={model.errors?.status}
              >
                <div>
                  <div className="label-status">
                    {translate("roles.status")}
                  </div>
                  <Switch
                    checked={model.statusId === 1 ? true : false}
                    onChange={handleChangeStatus}
                    className="switch_status"
                    disabled={isLinkAssginAppUser ? true : false}
                  />
                </div>
              </FormItem>
            </Col>
          </Row>

          {isLinkAssginAppUser ? (
            <>
              <div className="page-detail__title p-t--md">
                {translate("roles.assignAppUserList")}
              </div>
              {isDetail && (
                <div className="permission-role-detai_content_table">
                  <div className="page page-master m-b--lg m-t--sm">
                    <div className="page-master__content">
                      <div className="page-master__tag-filter">
                        <TagFilter
                          value={appUserFilter}
                          translate={translate}
                          keyTranslate={"roles"}
                          handleChangeFilter={handleChangeAppUserAllFilter}
                          onClear={(value: any) => {
                            return 0;
                          }}
                        />
                      </div>
                      <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                        <div className="page-master__filter d-flex align-items-center justify-content-start">
                          <div className="page-master__filter-action-search d-flex align-items-center"></div>
                        </div>

                        <div className="page-master__actions  d-flex align-items-center justify-content-start">
                          <div className="page-master__filter-action d-flex align-items-center">
                            <Button
                              type="primary"
                              className="btn--lg"
                              icon={<Add16 />}
                              onClick={handleOpenMapping}
                            >
                              {translate("roles.buttons.addAppUser")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="page-master__content-table">
                      <StandardTable
                        rowKey={nameof(appUsers[0].id)}
                        columns={appUserRoleMappingColumns}
                        dataSource={appUsers}
                        isDragable={true}
                        tableSize={"md"}
                        scroll={{ x: 1500 }}
                      />

                      {visible && (
                        <AppUserRoleAdvanceFilter
                          visible={visible}
                          handleClose={handleCancel}
                          visibleFooter={true}
                          loading={false}
                          size={"sm"}
                          filter={appUserFilter}
                          setVisible={setVisible}
                          handleChangeAllFilter={handleChangeAppUserAllFilter}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Tabs type="line">
                {isDetail && (
                  <TabPane tab={translate("roles.general.permission")} key="1">
                    <PermissionRoleDetail role={model} siteId={model.siteId} />
                  </TabPane>
                )}
                {model?.siteId && (
                  <>
                    <TabPane tab="Phân quyền dữ liệu chung" key="2">
                      <PermissionGeneralField
                        model={model}
                        dispatchModel={dispatch}
                      />
                    </TabPane>
                    <TabPane tab="Phân quyền thao tác chung" key="3">
                      <PermissionGeneralActionType
                        model={model}
                        dispatchModel={dispatch}
                      />
                    </TabPane>
                  </>
                )}
              </Tabs>
            </>
          )}
        </div>
        <footer className="app-footer">
          <div className="app-footer__full d-flex justify-content-end align-items-center">
            <div className="app-footer__actions d-flex justify-content-end">
              <Button
                type="secondary"
                className="btn--lg"
                icon={<Close16 />}
                onClick={handleGoMaster}
              >
                {translate("general.actions.close")}
              </Button>

              <Button
                type="primary"
                className="btn--lg"
                icon={<Send16 />}
                onClick={
                  isLinkAssginAppUser
                    ? () => handleAssignAppUser(model)
                    : handleSaveRole
                }
              >
                {translate("general.actions.save")}
              </Button>
            </div>
          </div>
        </footer>
      </div>
      {open && (
        <AppUserRoleMappingModal
          visible={open}
          handleSave={handleSaveMapping}
          handleClose={handleCancelMapping}
          translate={translate}
          appUserFilter={appUserFilter}
          handleSelectFilter={handleChangeSelectFilter}
          handleChangeInputSearch={handleChangeInputSearch}
          listAppUser={listMapping}
          countAppUser={countMapping}
          loadList={spinning}
          handlePagination={handlePagination}
          rowSelection={rowSelection}
          handleChangeInputFilter={handleChangeInputFilter}
          handleChangeAllFilter={handleChangeAppUserAllFilter}
          // handleChangeSelectFilter={handleChangeSelectFilter}
        />
      )}
    </>
  );
}

export default RoleDetail;
