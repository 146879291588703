/* begin general import */
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import { ColumnProps } from "antd/lib/table";
import { FieldModel, FieldModelFilter } from "models/FieldModel";
/* end general import */
/* begin individual import */
import {
  PermissionContent,
  PermissionContentFilter,
} from "models/PermissionContent";
import { PermissionOperatorFilter } from "models/PermissionOperator";
import { Role } from "models/Role";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormItem, LayoutCell, LayoutHeader, Select } from "react3l-ui-library";
import { roleRepository } from "repositories/role-repository";
import { utilService } from "services/common-services/util-service";
import { ModelAction } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import {
  FilterAction,
  filterReducer,
} from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { tableService } from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import GeneralFieldIdFieldInput from "./GeneralFieldIdFieldInput";
export function useGeneralFieldHook(
  model: Role,
  dispatch?: React.Dispatch<ModelAction<Role>>
) {
  const [translate] = useTranslation();

  const [generalFieldFilter, dispatchFilter] = React.useReducer<
    React.Reducer<
      PermissionContentFilter,
      FilterAction<PermissionContentFilter>
    >
  >(filterReducer, new PermissionContentFilter());

  const { handleChangeListField, handleChangeAllField } = fieldService.useField(
    model,
    dispatch
  );

  const { list: generalFields } = listService.useLocalList(
    model?.generalFields,
    generalFieldFilter
  );

  const { handleAddRow, handleDeleteRow } = tableService.useContentTable(
    PermissionContentFilter,
    PermissionContent,
    generalFields,
    handleChangeListField({ fieldName: "generalFields" }),
    dispatchFilter
  );

  const handleLocalDelete = React.useCallback(
    (content) => () => {
      handleDeleteRow(content?.key);
    },
    [handleDeleteRow]
  );

  const [resetIdFieldInput, setResetIdFieldInput] =
    React.useState<boolean>(false);

  const handleChangeMasterEntity = React.useCallback(
    (index: number) => (value: any, object?: any) => {
      const newModel = [...generalFields];
      newModel[index]["masterEntity"] = object;
      newModel[index]["masterEntityId"] = value;
      newModel[index]["permissionOperator"] = undefined;
      newModel[index]["permissionOperatorId"] = undefined;
      newModel[index]["value"] = undefined;
      newModel[index]["masterData"] = undefined;
      newModel[index]["masterDataId"] = undefined;
      setResetIdFieldInput(true);
      handleChangeAllField({ ...model, generalFields: newModel });
    },
    [handleChangeAllField, model, generalFields]
  );
  const handleChangePermissionOperator = React.useCallback(
    (index: number) => (value: any, object?: any) => {
      const newModel = [...generalFields];
      newModel[index]["permissionOperator"] = object;
      newModel[index]["permissionOperatorId"] = value;
      newModel[index]["value"] = undefined;
      newModel[index]["masterData"] = undefined;
      newModel[index]["masterDataId"] = undefined;
      setResetIdFieldInput(true);
      handleChangeAllField({ ...model, generalFields: newModel });
    },
    [handleChangeAllField, model, generalFields]
  );

  const generalFieldColumns: ColumnProps<PermissionContent>[] = React.useMemo(
    () => [
      {
        title: (
          <LayoutHeader
            orderType="center"
            title={translate("permissions.generalFields.masterEntity")}
          />
        ),
        width: 120,
        key: nameof(generalFields[0].masterEntity),
        dataIndex: nameof(generalFields[0].masterEntity),
        ellipsis: true,
        render(...params: [FieldModel, PermissionContent, number]) {
          return (
            <LayoutCell
              orderType="center"
              tableSize="lg"
              className="permission-content-field"
            >
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  nameof(params[1].masterEntity)
                )}
                message={params[1]?.errors?.masterEntity}
              >
                <Select
                  appendToBody
                  type={0}
                  classFilter={FieldModelFilter}
                  placeHolder={translate(
                    "permissions.generalFields.masterEntity"
                  )}
                  //   render={(t) => {
                  //     return t?.translatedName;
                  //   }}
                  getList={roleRepository.singleListMasterEntity}
                  onChange={handleChangeMasterEntity(params[2])}
                  value={params[1]?.masterEntity}
                />
              </FormItem>
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="center"
            title={translate("permissions.generalFields.permissionOperator")}
          />
        ),
        ellipsis: true,
        key: nameof(generalFields[0].permissionOperator),
        width: 120,
        dataIndex: nameof(generalFields[0].permissionOperator),
        render(...params: [number, PermissionContent, number]) {
          const permissionOperatorFilter = new PermissionOperatorFilter();
          permissionOperatorFilter.fieldTypeId.equal = 1;
          return (
            <LayoutCell
              orderType="center"
              tableSize="lg"
              className="permission-content-field"
            >
              {params[1].permissionOperator?.name === "UserId" &&
              params[0] === 101 ? (
                "="
              ) : (
                <>
                  {generalFields[params[2]]?.masterEntityId &&
                  generalFields[params[2]]?.masterEntity ? (
                    <FormItem
                      validateStatus={utilService.getValidateStatus(
                        params[1],
                        nameof(params[1].permissionOperator)
                      )}
                      message={params[1]?.errors?.permissionOperator}
                    >
                      <Select
                        appendToBody
                        valueFilter={permissionOperatorFilter}
                        type={0}
                        classFilter={FieldModelFilter}
                        placeHolder={translate(
                          "permissions.generalFields.permissionOperator"
                        )}
                        getList={roleRepository.singleListPermissionOperator}
                        onChange={handleChangePermissionOperator(params[2])}
                        value={params[1]?.permissionOperator}
                      />
                    </FormItem>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="center"
            title={translate("permissions.generalFields.value")}
          />
        ),
        key: nameof(generalFields[0].value),
        width: 120,
        ellipsis: true,
        dataIndex: nameof(generalFields[0].value),
        render(...params: [string, PermissionContent, number]) {
          return (
            <LayoutCell
              orderType="center"
              tableSize="lg"
              className="permission-content-field"
            >
              {generalFields[params[2]]?.masterEntityId &&
              generalFields[params[2]]?.permissionOperatorId &&
              generalFields[params[2]]?.masterEntity &&
              generalFields[params[2]]?.permissionOperator ? (
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    params[1],
                    nameof(params[1].value)
                  )}
                  message={params[1]?.errors?.value}
                >
                  <GeneralFieldIdFieldInput
                    value={params[0]}
                    index={params[2]}
                    contents={generalFields}
                    setContents={handleChangeListField({
                      fieldName: "generalFields",
                    })}
                    disabled={params[1].masterEntityId === undefined}
                    searchBy={"masterEntity"}
                    resetValue={resetIdFieldInput}
                    setResetValue={setResetIdFieldInput}
                  />
                </FormItem>
              ) : (
                <></>
              )}
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="center"
            title={translate("general.actions.label")}
          />
        ),
        key: "action",
        dataIndex: nameof(generalFields[0].key),
        fixed: "right",
        width: 60,
        align: "center",
        render(...params: [string, PermissionContent, number]) {
          return (
            <TrashCan16 color="red" onClick={handleLocalDelete(params[1])} />
          );
        },
      },
    ],
    [
      translate,
      generalFields,
      handleChangeMasterEntity,
      handleChangePermissionOperator,
      handleChangeListField,
      resetIdFieldInput,
      handleLocalDelete,
    ]
  );

  return {
    generalFields,
    generalFieldColumns,
    handleAddGeneralField: handleAddRow,
  };
}
