import { ObjectField, ObjectList } from "react3l-decorators";
import { Menu } from "models/Menu";
import { FileTemplate } from "./FileTemplate";
import nameof from "ts-nameof.macro";
import { Page } from "models/Page";
import { Site } from "models/Site";
import { FileTemplateInput } from "models/FileTemplateInput";
import { EnumModel } from "models/Enum";
import { AppUser } from "models/AppUser";

ObjectField(EnumModel)(
  FileTemplate.prototype,
  nameof(FileTemplate.prototype.selfDefineInput)
);
ObjectField(EnumModel)(
  FileTemplate.prototype,
  nameof(FileTemplate.prototype.default)
);
ObjectField(Menu)(FileTemplate.prototype, nameof(FileTemplate.prototype.menu));
ObjectField(Page)(FileTemplate.prototype, nameof(FileTemplate.prototype.page));
ObjectField(Site)(FileTemplate.prototype, nameof(FileTemplate.prototype.site));
ObjectList(FileTemplateInput)(
  FileTemplate.prototype,
  nameof(FileTemplate.prototype.fileTemplateInputs)
);
ObjectField(AppUser)(
  FileTemplate.prototype,
  nameof(FileTemplate.prototype.creator)
);
ObjectField(AppUser)(
  FileTemplate.prototype,
  nameof(FileTemplate.prototype.modifier)
);

export * from "./FileTemplate";
export * from "./FileTemplateFilter";
