import { ColumnProps } from "antd/lib/table";
import { MENU_REPORT_ROUTE } from "config/route-consts";
import { renderMasterIndex } from "helpers/table";
import { MenuReport } from "models/MenuReport";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormItem,
  InputText,
  LayoutCell,
  LayoutHeader,
  Select,
} from "react3l-ui-library";
import { menuReportRepository } from "repositories/menu-report-repository";
import { detailService } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import { filterService } from "services/page-services/filter-service";
import { listService } from "services/page-services/list-service";
import { tableService } from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import Copy16 from "@carbon/icons-react/es/copy/16";
import { Popconfirm, Switch } from "antd";
import UploadFile, {
  FileModel,
} from "react3l-ui-library/build/components/UploadFile/UploadFile";
import { join } from "path";
import { API_MENU_REPORT_PREFIX } from "config/api-consts";
import { FileTemplate, FileTemplateFilter } from "models/FileTemplate";
import { Organization, OrganizationFilter } from "models/Organization";
import { useFileTemplateInputModalHook } from "./FileTemplateInputModalHook";
import { FileTemplateInput } from "models/FileTemplateInput";
import { utilService } from "services/common-services/util-service";
import { RowSelectionType } from "antd/lib/table/interface";
type KeyType = string | number;

export function useMenuReportDetailHook() {
  const [translate] = useTranslation();

  const { model, dispatch } = detailService.useModel<MenuReport>(MenuReport, {
    ...new MenuReport(),
    statusId: 1,
  });

  const { isDetail } = detailService.useGetIsDetail<MenuReport>(
    menuReportRepository.get,
    dispatch
  );

  const {
    handleChangeAllField,
    handleChangeSelectField,
    handleChangeSingleField,
    handleChangeBoolField,
    handleChangeListField,
  } = fieldService.useField(model, dispatch);

  const { handleSaveModel, handleGoMaster } =
    detailService.useActionsDetail<MenuReport>(
      model,
      menuReportRepository.save,
      handleChangeAllField,
      MENU_REPORT_ROUTE
    );

  const {
    modelFilter: fileTemplateFilter,
    dispatchFilter: dispatchFileTemplateFilter,
  } = filterService.useModelFilter(FileTemplateFilter);

  const { list: listFileTemplate, count: countFileTemplate } =
    listService.useLocalList(model?.fileTemplates, fileTemplateFilter);

  const {
    handleChangeCell,
    handleChangeRow,
    handleAddRow,
    handleDeleteRow,
    handleInsertRow,
  } = tableService.useContentTable<FileTemplate, FileTemplateFilter>(
    FileTemplateFilter,
    FileTemplate,
    listFileTemplate,
    handleChangeListField({ fieldName: "fileTemplates" }),
    dispatchFileTemplateFilter
  );

  const variablesFileDownloadPath: string = React.useMemo<string>(() => {
    const menuReportId = model.id;
    return join(
      "/" + API_MENU_REPORT_PREFIX,
      `/variables-file/download?id=${menuReportId}`
    );
  }, [model.id]);

  const handleRemoveFile = React.useCallback(
    (value: FileTemplate) => () => {
      handleChangeRow(value.key, { ...value, file: null, fileId: null });
    },
    [handleChangeRow]
  );

  const handleUpdateFile = React.useCallback(
    (value: FileTemplate) => (files: FileModel[]) => {
      if (files && files.length) {
        handleChangeRow(value.key, {
          ...value,
          file: files[0],
          fileId: files[0].id,
        });
      }
    },
    [handleChangeRow]
  );

  const handleChangeOrganization = React.useCallback(
    (value: FileTemplate) =>
      (organizationId: number, organization: Organization) => {
        handleChangeRow(value.key, {
          ...value,
          organization: organization,
          organizationId: organizationId,
        });
      },
    [handleChangeRow]
  );

  const handleCloneFileTemplate = React.useCallback(
    (index: number, fileTempalte: FileTemplate) => () => {
      const newFileTemplate = new FileTemplate();
      newFileTemplate.fileTemplateInputs =
        fileTempalte &&
        fileTempalte.fileTemplateInputs &&
        fileTempalte.fileTemplateInputs.length > 0
          ? [...fileTempalte.fileTemplateInputs]
          : [];
      newFileTemplate.selfDefineInputId = 1;
      handleInsertRow(index, newFileTemplate);
    },
    [handleInsertRow]
  );

  const handleUpdateFileTemplateInput = React.useCallback(
    (fileTemplateInputs: FileTemplateInput[], fileTemplate: FileTemplate) => {
      handleChangeCell(
        fileTemplate.key,
        "fileTemplateInputs",
        fileTemplateInputs
      );
    },
    [handleChangeCell]
  );

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<KeyType[]>([]);

  const rowSelection = React.useMemo(
    () => ({
      onChange(selectedRowKeys: KeyType[]) {
        setSelectedRowKeys(selectedRowKeys);
      },
      selectedRowKeys,
      type: "checkbox" as RowSelectionType,
      getCheckboxProps: (record: FileTemplate) => ({
        disabled: record.used,
      }),
    }),
    [selectedRowKeys]
  );

  const canBulkAction = React.useMemo(
    () => selectedRowKeys.length > 0,
    [selectedRowKeys.length]
  );

  const handleBulkDelete = React.useCallback(() => {
    const selectedRowKeysValue = [...selectedRowKeys];
    const listFileTemplateValue = [...listFileTemplate].filter(
      (currentFile: FileTemplate) =>
        !selectedRowKeysValue.includes(currentFile.key)
    );
    handleChangeListField({ fieldName: "fileTemplates" })(
      listFileTemplateValue
    );
    setSelectedRowKeys([]);
  }, [selectedRowKeys, listFileTemplate, handleChangeListField]);

  const {
    loadingType,
    handleOpenModal,
    handleSaveModal,
    handleCloseModal,
    handleAddFileTemplateInput,
    handleChangeFileTemplateInputCell,
    setSelectedRowKeysFileTemplateInput,
    handleBulkDeleteFileTemplateInput,
    selectedRowKeysFileTemplateInput,
    rowSelectionFileTemplateInput,
    canBulkActionFileTemplateInput,
    fileTemplateInputColumns,
    fileTemplateInputsInternal,
    visible,
  } = useFileTemplateInputModalHook(handleUpdateFileTemplateInput, translate);

  const columns: ColumnProps<FileTemplate>[] = React.useMemo(() => {
    return [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
          />
        ),
        key: "index",
        width: 80,
        align: "center",
        render: renderMasterIndex<FileTemplate>(),
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.organization")}
          />
        ),
        key: nameof("organization"),
        dataIndex: nameof("organization"),
        render(...params: [Organization, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  "organizationId"
                )}
                message={utilService.getValidateMessage(
                  params[1],
                  "organizationId"
                )}
              >
                <Select
                  classFilter={OrganizationFilter}
                  placeHolder={translate(
                    "fileTemplates.placeholder.organization"
                  )}
                  getList={menuReportRepository.singleListOrganization}
                  onChange={handleChangeOrganization(params[1])}
                  value={params[1].organization}
                  appendToBody
                  type={0}
                />
              </FormItem>
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.name")}
          />
        ),
        key: nameof("name"),
        dataIndex: nameof("name"),
        render(...params: [string, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <FormItem
                validateStatus={utilService.getValidateStatus(
                  params[1],
                  "name"
                )}
                message={utilService.getValidateMessage(params[1], "name")}
              >
                {" "}
                <InputText
                  type={0}
                  value={params[0]}
                  placeHolder={translate("fileTemplates.placeholder.name")}
                  onChange={(value: string) => {
                    handleChangeCell(params[1].key, "name", value);
                  }}
                />
              </FormItem>
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.file")}
          />
        ),
        width: 400,
        key: nameof("file"),
        dataIndex: nameof("file"),
        render(...params: [FileModel, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <div className="field-upload__button">
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    params[1],
                    "fileId"
                  )}
                  message={utilService.getValidateMessage(params[1], "fileId")}
                >
                  <UploadFile
                    files={params[1]?.file ? [params[1]?.file] : []}
                    uploadContent={translate("fileTemplates.addFile")}
                    uploadFile={menuReportRepository.upload}
                    updateList={handleUpdateFile(params[1])}
                    removeFile={handleRemoveFile(params[1])}
                    isMultiple={false}
                    type="link"
                  ></UploadFile>
                </FormItem>
              </div>
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.selfDefineInput")}
          />
        ),
        width: 120,
        key: nameof("selfDefineInput"),
        dataIndex: nameof("selfDefineInput"),
        render(...params: [number, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <Switch
                checked={params[1].selfDefineInputId === 1 ? true : false}
                onChange={(value: boolean) => {
                  handleChangeCell(
                    params[1].key,
                    "selfDefineInputId",
                    value ? 1 : 0
                  );
                }}
              />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.selfDefineInputId")}
          />
        ),
        width: 120,
        key: nameof("selfDefineInputId"),
        dataIndex: nameof("selfDefineInputId"),
        render(...params: [number, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              {params[0] ? (
                <FormItem
                  validateStatus={utilService.getValidateStatus(
                    params[1],
                    "selfDefineInputId"
                  )}
                  message={utilService.getValidateMessage(
                    params[1],
                    "selfDefineInputId"
                  )}
                >
                  <Button
                    type="link"
                    onClick={() => {
                      handleOpenModal(params[1]);
                    }}
                  >
                    {"Click để xem"}
                  </Button>
                </FormItem>
              ) : null}
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.status")}
          />
        ),
        width: 120,
        key: nameof("order"),
        dataIndex: nameof("order"),
        render(...params: [number, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <Switch
                checked={params[1].statusId === 1 ? true : false}
                onChange={(value: boolean) => {
                  handleChangeCell(params[1].key, "statusId", value ? 1 : 0);
                }}
              />
            </LayoutCell>
          );
        },
      },
      {
        key: nameof("action"),
        dataIndex: nameof("action"),
        fixed: "right",
        width: 120,
        align: "center",
        render(...params: [string, FileTemplate, number]) {
          return (
            <div className="d-flex w-100 justify-content-center">
              <Popconfirm
                title={translate("general.warnings.delete")}
                onConfirm={() => {
                  handleDeleteRow(params[1]?.key);
                }}
                okText={translate("general.actions.accept")}
                cancelText={translate("general.actions.deny")}
              >
                <TrashCan16 color="red" className="m-r--sm" />
              </Popconfirm>
              <Copy16 onClick={handleCloneFileTemplate(params[2], params[1])} />
            </div>
          );
        },
      },
    ];
  }, [
    handleChangeCell,
    handleChangeOrganization,
    handleCloneFileTemplate,
    handleDeleteRow,
    handleOpenModal,
    handleRemoveFile,
    handleUpdateFile,
    translate,
  ]);

  return {
    // table contents
    translate,
    handleChangeSelectField,
    handleChangeSingleField,
    handleSaveModel,
    handleGoMaster,
    handleAddRow,
    handleChangeBoolField,
    variablesFileDownloadPath,
    listFileTemplate,
    countFileTemplate,
    isDetail,
    fileTemplateColumns: columns,
    model,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
    canBulkAction,
    handleBulkDelete,
    // modal contents
    loadingType,
    handleOpenModal,
    handleSaveModal,
    handleCloseModal,
    handleAddFileTemplateInput,
    handleChangeFileTemplateInputCell,
    setSelectedRowKeysFileTemplateInput,
    handleBulkDeleteFileTemplateInput,
    selectedRowKeysFileTemplateInput,
    rowSelectionFileTemplateInput,
    canBulkActionFileTemplateInput,
    fileTemplateInputColumns,
    fileTemplateInputsInternal,
    visible,
  };
}
