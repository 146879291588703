import { ColumnProps } from "antd/lib/table";
import { FILE_TEMPLATE_ROUTE } from "config/route-consts";
import { renderMasterIndex } from "helpers/table";
import { MenuReport } from "models/MenuReport";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  LayoutCell,
  LayoutHeader,
  OneLineText,
  StatusLine,
  UploadFile,
} from "react3l-ui-library";
import { menuReportRepository } from "repositories/menu-report-repository";
import { detailService } from "services/page-services/detail-service";
import { fieldService } from "services/page-services/field-service";
import nameof from "ts-nameof.macro";
import { Organization } from "models/Organization";
import { FileModel, FileTemplate } from "models/FileTemplate";
import { EnumModel } from "models/Enum";
import { FileTemplateInput } from "models/FileTemplateInput";
import { API_MENU_REPORT_PREFIX } from "config/api-consts";
import { join } from "path";

export function useMenuReportPreviewHook() {
  const [translate] = useTranslation();
  const { model, dispatch } = detailService.useModel<MenuReport>(MenuReport);
  detailService.useGetIsDetail<MenuReport>(menuReportRepository.get, dispatch);
  const { handleChangeAllField } = fieldService.useField(model, dispatch);
  const { handleGoMaster } = detailService.useActionsDetail<MenuReport>(
    model,
    menuReportRepository.save,
    handleChangeAllField,
    FILE_TEMPLATE_ROUTE
  );
  const [currentFileTemplateInputs, setCurrentFileTemplateInputs] =
    React.useState<FileTemplateInput[]>([]);
  const [visibleFileTemplateInputModal, setVisibleFileTemplateInputModal] =
    React.useState<boolean>(false);
  const handleOpenTemplateInputModal = React.useCallback(
    (currentFileTemplate: FileTemplate) => () => {
      const fileTemplateInputs =
        currentFileTemplate.fileTemplateInputs &&
        currentFileTemplate.fileTemplateInputs.length > 0
          ? currentFileTemplate.fileTemplateInputs
          : [];
      setCurrentFileTemplateInputs([...fileTemplateInputs]);
      setVisibleFileTemplateInputModal(true);
    },
    []
  );

  const handleCloseTemplateInputModal = React.useCallback(() => {
    setVisibleFileTemplateInputModal(false);
  }, []);

  const variablesFileDownloadPath: string = React.useMemo<string>(() => {
    const menuReportId = model.id;
    return join(
      "/" + API_MENU_REPORT_PREFIX,
      `/variables-file/download?id=${menuReportId}`
    );
  }, [model.id]);

  const columns: ColumnProps<FileTemplate>[] = React.useMemo(() => {
    return [
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("general.columns.index")}
          />
        ),
        key: "index",
        width: 80,
        align: "center",
        render: renderMasterIndex<FileTemplate>(),
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.organization")}
          />
        ),
        key: nameof("organization"),
        dataIndex: nameof("organization"),
        render(...params: [Organization, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <OneLineText value={params[0]?.name} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.name")}
          />
        ),
        key: nameof("name"),
        dataIndex: nameof("name"),
        render(...params: [string, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.file")}
          />
        ),
        key: nameof("file"),
        dataIndex: nameof("file"),
        render(...params: [FileModel, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <div className="field-upload__button">
                <UploadFile
                  files={params[1]?.file ? [params[1]?.file] : []}
                  isMultiple={false}
                  type="button"
                  isViewMode={true}
                ></UploadFile>
              </div>
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.selfDefineInput")}
          />
        ),
        key: nameof("selfDefineInput"),
        dataIndex: nameof("selfDefineInput"),
        render(...params: [EnumModel, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <StatusLine value={params[0]?.name} color={params[0]?.color} />
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.selfDefineInputId")}
          />
        ),
        key: nameof("selfDefineInputId"),
        dataIndex: nameof("selfDefineInputId"),
        render(...params: [number, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              {params[0] && (
                <Button
                  type="link"
                  onClick={handleOpenTemplateInputModal(params[1])}
                >
                  {"Click để xem dữ liệu tự định nghĩa"}
                </Button>
              )}
            </LayoutCell>
          );
        },
      },
      {
        title: (
          <LayoutHeader
            orderType="left"
            title={translate("fileTemplates.status")}
          />
        ),
        key: nameof("status"),
        dataIndex: nameof("status"),
        render(...params: [EnumModel, FileTemplate, number]) {
          return (
            <LayoutCell orderType="left" tableSize="lg">
              <StatusLine value={params[0]?.name} color={params[0]?.color} />
            </LayoutCell>
          );
        },
      },
    ];
  }, [handleOpenTemplateInputModal, translate]);

  const columnFileTemplateInputs: ColumnProps<FileTemplateInput>[] =
    React.useMemo(() => {
      return [
        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate("general.columns.index")}
            />
          ),
          key: "index",
          width: 80,
          align: "center",
          render: renderMasterIndex<FileTemplateInput>(),
        },
        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate("fileTemplateInputs.fieldName")}
            />
          ),
          key: nameof("fieldName"),
          dataIndex: nameof("fieldName"),
          render(...params: [string, FileTemplateInput, number]) {
            return (
              <LayoutCell orderType="left" tableSize="lg">
                <OneLineText value={params[0]} />
              </LayoutCell>
            );
          },
        },
        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate("fileTemplateInputs.fieldValue")}
            />
          ),
          key: nameof("fieldValue"),
          dataIndex: nameof("fieldValue"),
          render(...params: [string, FileTemplateInput, number]) {
            return (
              <LayoutCell orderType="left" tableSize="lg">
                <OneLineText value={params[0]} countCharacters={50} />
              </LayoutCell>
            );
          },
        },
        {
          title: (
            <LayoutHeader
              orderType="left"
              title={translate("fileTemplateInputs.order")}
            />
          ),
          width: 200,
          key: nameof("order"),
          dataIndex: nameof("order"),
          render(...params: [number, FileTemplateInput, number]) {
            return (
              <LayoutCell orderType="left" tableSize="lg">
                <OneLineText value={params[0]?.toString()} />
              </LayoutCell>
            );
          },
        },
      ];
    }, [translate]);

  return {
    translate,
    handleGoMaster,
    handleCloseTemplateInputModal,
    variablesFileDownloadPath,
    model,
    columns,
    columnFileTemplateInputs,
    visibleFileTemplateInputModal,
    currentFileTemplateInputs,
  };
}
