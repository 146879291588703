import Add16 from "@carbon/icons-react/es/add/16";
import DocumentPdf16 from "@carbon/icons-react/es/document--pdf/16";
import Download16 from "@carbon/icons-react/es/download/16";
import SettingsAdjust16 from "@carbon/icons-react/es/settings--adjust/16";
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import Upload16 from "@carbon/icons-react/es/upload/16";
import { Dropdown, Menu } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageHeader from "components/PageHeader/PageHeader";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionBarComponent,
  AdvanceEnumFilterMaster,
  Button,
  LayoutCell,
  LayoutHeader,
  OneLineText,
  Pagination,
  StandardTable,
  StatusLine,
  TagFilter,
} from "react3l-ui-library";
import InputSearch from "react3l-ui-library/build/components/Input/InputSearch";
import { detailService } from "services/page-services/detail-service";
import { filterService } from "services/page-services/filter-service";
import { importExportService } from "services/page-services/import-export-service";
import { listService } from "services/page-services/list-service";
import { masterService } from "services/page-services/master-service";
import { queryStringService } from "services/page-services/query-string-service";
import {
  getAntOrderType,
  tableService,
} from "services/page-services/table-service";
import nameof from "ts-nameof.macro";
import PositionAdvanceFilter from "./PositionAdvanceFilter";
/* end general import */

/* begin filter import */
import { IdFilter } from "react3l-advanced-filters";
/* end filter import */

/* begin individual import */
import OverflowMenuHorizontal24 from "@carbon/icons-react/es/overflow-menu--horizontal/24";
import { POSITION_ROUTE } from "config/route-consts";
import { Position, PositionFilter } from "models/Position";
import { Status } from "models/Status";
import { positionRepository } from "repositories/position-repository";
import PositionDetailDrawer from "../PositionDetail/PositionDetailDrawer";
/* end individual import */

function PositionMaster() {
  const [translate] = useTranslation();

  const [modelFilter, dispatch] = queryStringService.useQueryString(
    PositionFilter,
    { skip: 0, take: 10 }
  );

  const [visible, setVisible] = React.useState<boolean>(false);

  const {
    value: filter,
    handleChangeSelectFilter,
    handleChangeInputSearch,
    handleChangeAllFilter,
  } = filterService.useFilter(modelFilter, dispatch);

  const { list, count, loadingList, handleResetList, handleLoadList } =
    listService.useList(
      positionRepository.list,
      positionRepository.count,
      filter,
      dispatch
    );

  const { handleTableChange, handlePagination } = tableService.useTable(
    filter,
    handleChangeAllFilter
  );

  const {
    handleAction,
    handleBulkAction,
    canBulkAction,
    rowSelection,
    selectedRowKeys,
    setSelectedRowKeys,
  } = listService.useRowSelection(
    positionRepository.delete,
    positionRepository.bulkDelete,
    null,
    null,
    null,
    handleResetList
  );

  const {
    handleImportList,
    ref: importButtonRef,
    handleClick,
  } = importExportService.useImport(handleResetList);

  const { handleListExport, handleExportTemplateList } =
    importExportService.useExport();

  const { handleDeleteItem } = masterService.useMasterAction(
    POSITION_ROUTE,
    handleAction
  );

  const {
    model,
    dispatch: dispatchModal,
    isOpenDetailModal,
    handleOpenDetailModal,
    handleCloseDetailModal,
    handleSaveModel,
    loadingModel,
    handleChangeSingleField,
    handleChangeSelectField,
    handleChangeDateField,
  } = detailService.useDetailModal(
    Position,
    positionRepository.get,
    positionRepository.save,
    handleLoadList
  );

  const menuAction = React.useCallback(
    (id: number, position: Position) => (
      <Menu>
        <Menu.Item key="1">
          <div
            className="ant-action-menu"
            onClick={() => handleOpenDetailModal(id)}
          >
            {translate("general.actions.view")}
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div
            className="ant-action-menu"
            onClick={() => handleOpenDetailModal(id)}
          >
            {translate("general.actions.edit")}
          </div>
        </Menu.Item>
        {!position.used && (
          <Menu.Item key="3">
            <div
              className="ant-action-menu"
              onClick={handleDeleteItem(position)}
            >
              {translate("general.actions.delete")}
            </div>
          </Menu.Item>
        )}
      </Menu>
    ),
    [handleOpenDetailModal, handleDeleteItem, translate]
  );

  const columns: ColumnProps<Position>[] = useMemo(
    () => [
      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "code"
          );
          return (
            <div>
              <LayoutHeader
                orderType="left"
                title={translate("positions.code")}
                sortedColumn={sortedColumn}
                isSorter
              />
            </div>
          );
        },
        key: nameof(list[0].code),
        dataIndex: nameof(list[0].code),
        sorter: true,
        sortOrder: getAntOrderType<Position, PositionFilter>(
          filter,
          nameof(list[0].code)
        ),
        ellipsis: true,
        render(...params: [string, Position, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "name"
          );
          return (
            <div>
              <LayoutHeader
                orderType="left"
                title={translate("positions.name")}
                sortedColumn={sortedColumn}
                isSorter
              />
            </div>
          );
        },
        key: nameof(list[0].name),
        dataIndex: nameof(list[0].name),
        sorter: true,
        sortOrder: getAntOrderType<Position, PositionFilter>(
          filter,
          nameof(list[0].name)
        ),
        ellipsis: true,
        render(...params: [string, Position, number]) {
          return (
            <LayoutCell orderType="left" tableSize="md">
              <OneLineText value={params[0]} />
            </LayoutCell>
          );
        },
      },

      {
        title: ({ sortColumns }) => {
          const sortedColumn = sortColumns?.find(
            ({ column }) => column.key === "status"
          );
          return (
            <div>
              <LayoutHeader
                orderType="left"
                title={translate("positions.status")}
                sortedColumn={sortedColumn}
                isSorter
              />
            </div>
          );
        },
        key: nameof(list[0].status),
        dataIndex: nameof(list[0].status),
        sorter: true,
        sortOrder: getAntOrderType<Position, PositionFilter>(
          filter,
          nameof(list[0].status)
        ),
        ellipsis: true,
        render(status: Status) {
          return (
            //fill the change the render field after generate code;
            //if status change OneLine = StatusLine
            <LayoutCell orderType="left" tableSize="md">
              <StatusLine value={status?.name} color={status?.color} />
            </LayoutCell>
          );
        },
      },

      {
        key: "action",
        dataIndex: nameof(list[0].id),
        fixed: "right",
        width: 80,
        align: "center",
        render(id: number, position: Position) {
          return (
            <div className="d-flex justify-content-center button-action-table">
              <Dropdown
                overlay={menuAction(id, position)}
                trigger={["click"]}
                placement="bottom"
                arrow
              >
                <OverflowMenuHorizontal24 />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [translate, list, filter, menuAction]
  );

  const handleCancel = React.useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <>
      <div className="page-content">
        <PageHeader
          title={translate("positions.master.subHeader")}
          breadcrumbItems={[
            translate("positions.master.header"),
            // translate("positions.master.subHeader"),
          ]}
        />
        <div className="page page-master m-t--lg m-l--sm m-r--xxl m-b--xxs">
          <div className="page-master__title p-l--sm p-t--sm p-r--sm p-b--lg">
            {translate("positions.master.title")}
          </div>
          <div className="page-master__content">
            <div className="page-master__tag-filter">
              <TagFilter
                value={filter}
                translate={translate}
                keyTranslate={"positions"}
                handleChangeFilter={handleChangeAllFilter}
                onClear={(value: any) => {
                  return 0;
                }}
              />
            </div>
            {(!selectedRowKeys || selectedRowKeys?.length === 0) && (
              <div className="page-master__filter-wrapper d-flex align-items-center justify-content-between">
                <div className="page-master__filter d-flex align-items-center justify-content-start">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <AdvanceEnumFilterMaster
                        placeHolder={translate("positions.placeholder.status")}
                        value={filter[nameof(list[0].statusId)]["equal"]}
                        onChange={handleChangeSelectFilter({
                          fieldName: nameof(list[0].status),
                          fieldType: "equal",
                          classFilter: IdFilter,
                        })}
                        getList={positionRepository.filterListStatus}
                        label={translate("positions.status")}
                      />
                    </div>
                  </div>
                </div>
                <div className="page-master__filter-action-search d-flex align-items-center">
                  <Button
                    type="icon-only-ghost"
                    icon={<SettingsAdjust16 />}
                    onClick={() => setVisible(true)}
                    className="btn--xl"
                  />
                  <InputSearch
                    value={filter?.search}
                    classFilter={PositionFilter}
                    placeHolder={translate("general.placeholder.search")}
                    onChange={handleChangeInputSearch}
                    animationInput={filter?.search ? false : true}
                  />
                </div>
                <div className="page-master__actions  d-flex align-items-center justify-content-start">
                  <div className="page-master__filter-action d-flex align-items-center">
                    <input
                      ref={importButtonRef}
                      type="file"
                      style={{ display: "none" }}
                      id="master-import"
                      onClick={handleClick}
                      onChange={handleImportList(positionRepository.import)}
                    />
                    <Button
                      type="icon-only-ghost"
                      icon={<Download16 />}
                      onClick={handleListExport(
                        filter,
                        positionRepository.export
                      )}
                      className="btn--xl"
                    />
                    <Button
                      type="icon-only-ghost"
                      icon={<Upload16 />}
                      onClick={() => {
                        importButtonRef.current.click();
                      }}
                      className="btn--xl"
                    />
                    <Button
                      type="icon-only-ghost"
                      icon={<DocumentPdf16 />}
                      onClick={handleExportTemplateList(
                        positionRepository.exportTemplate
                      )}
                      className="btn--xl"
                    />
                    <Button
                      type="primary"
                      className="btn--lg"
                      icon={<Add16 />}
                      onClick={() => handleOpenDetailModal(null)}
                    >
                      {translate("general.actions.create")}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="page-master__content-table">
            <ActionBarComponent
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              translateTitleCancelButton={translate("general.actions.close")}
            >
              <Button
                icon={<TrashCan16 />}
                type="ghost-primary"
                className="btn--lg"
                disabled={!canBulkAction}
                onClick={() => handleBulkAction(selectedRowKeys)}
              >
                {translate("general.actions.delete")}
              </Button>
            </ActionBarComponent>
            <StandardTable
              rowKey={nameof(list[0].id)}
              columns={columns}
              dataSource={list}
              isDragable={true}
              tableSize={"md"}
              onChange={handleTableChange}
              loading={loadingList}
              rowSelection={rowSelection}
              scroll={{ x: 1000 }}
            />

            <Pagination
              skip={filter?.skip}
              take={filter?.take}
              total={count}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
      {visible && (
        <PositionAdvanceFilter
          visible={visible}
          handleClose={handleCancel}
          visibleFooter={true}
          loading={false}
          size={"sm"}
          filter={filter}
          setVisible={setVisible}
          handleChangeAllFilter={handleChangeAllFilter}
        />
      )}
      <PositionDetailDrawer
        model={model}
        visible={isOpenDetailModal}
        handleSave={handleSaveModel}
        handleCancel={handleCloseDetailModal}
        handleChangeSingleField={handleChangeSingleField}
        handleChangeSelectField={handleChangeSelectField}
        handleChangeDateField={handleChangeDateField}
        dispatch={dispatchModal}
        loading={loadingModel}
        visibleFooter={true}
      />
    </>
  );
}
export default PositionMaster;
