import { API_LOGIN_CONFIGURATION_PREFIX } from "config/api-consts";
import { BASE_API_URL } from "config/consts";
import { httpConfig } from "config/http";
import { kebabCase } from "lodash";
import {
  LoginConfiguration,
  LoginConfigurationFilter,
} from "models/LoginConfiguration";
import { Status, StatusFilter } from "models/Status";
import { Repository } from "react3l-common";
import { Observable } from "rxjs";
import nameof from "ts-nameof.macro";

export type KeyType = string | number;

export class LoginConfigurationRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.baseURL = new URL(API_LOGIN_CONFIGURATION_PREFIX, BASE_API_URL).href;
  }

  public count = (
    loginConfigurationFilter?: LoginConfigurationFilter
  ): Observable<number> => {
    return this.http
      .post<number>(kebabCase(nameof(this.count)), loginConfigurationFilter)
      .pipe(Repository.responseDataMapper<number>());
  };

  public list = (
    loginConfigurationFilter?: LoginConfigurationFilter
  ): Observable<LoginConfiguration[]> => {
    return this.http
      .post<LoginConfiguration[]>(
        kebabCase(nameof(this.list)),
        loginConfigurationFilter
      )
      .pipe(
        Repository.responseMapToList<LoginConfiguration>(LoginConfiguration)
      );
  };

  public get = (id: number | string): Observable<LoginConfiguration> => {
    return this.http
      .post<LoginConfiguration>(kebabCase(nameof(this.get)), { id })
      .pipe(
        Repository.responseMapToModel<LoginConfiguration>(LoginConfiguration)
      );
  };

  public create = (
    loginConfiguration: LoginConfiguration
  ): Observable<LoginConfiguration> => {
    return this.http
      .post<LoginConfiguration>(
        kebabCase(nameof(this.create)),
        loginConfiguration
      )
      .pipe(
        Repository.responseMapToModel<LoginConfiguration>(LoginConfiguration)
      );
  };

  public update = (
    loginConfiguration: LoginConfiguration
  ): Observable<LoginConfiguration> => {
    return this.http
      .post<LoginConfiguration>(
        kebabCase(nameof(this.update)),
        loginConfiguration
      )
      .pipe(
        Repository.responseMapToModel<LoginConfiguration>(LoginConfiguration)
      );
  };

  public delete = (
    loginConfiguration: LoginConfiguration
  ): Observable<LoginConfiguration> => {
    return this.http
      .post<LoginConfiguration>(
        kebabCase(nameof(this.delete)),
        loginConfiguration
      )
      .pipe(
        Repository.responseMapToModel<LoginConfiguration>(LoginConfiguration)
      );
  };

  public bulkDelete = (idList?: KeyType[]): Observable<void> => {
    return this.http
      .post(kebabCase(nameof(this.bulkDelete)), idList)
      .pipe(Repository.responseDataMapper());
  };

  public save = (
    loginConfiguration: LoginConfiguration
  ): Observable<LoginConfiguration> => {
    return loginConfiguration.id
      ? this.update(loginConfiguration)
      : this.create(loginConfiguration);
  };

  public singleListStatus = (): Observable<Status[]> => {
    return this.http
      .post<Status[]>(
        kebabCase(nameof(this.singleListStatus)),
        new StatusFilter()
      )
      .pipe(Repository.responseMapToList<Status>(Status));
  };

  public filterListStatus = (
    statusFilter: StatusFilter
  ): Observable<Status[]> => {
    return this.http
      .post<Status[]>(kebabCase(nameof(this.filterListStatus)), statusFilter)
      .pipe(Repository.responseMapToList<Status>(Status));
  };
}

export const loginConfigurationRepository = new LoginConfigurationRepository();
